// @flow

import { connect } from 'react-redux'

import {
  fetchTimetableAdvanced,
  resetStatusTimetableAdvanced
} from '../actions/trip'
import TimetableAdvancedModal from '../components/TimetableAdvancedModal'
import {findProjectByFeedSource} from '../../manager/util'

import type {AppState, RouteParams} from '../../types/reducers'

export type Props = {
  activeEntityId: number,
  feedSource: Feed,
  project: Project,
  onClose: () => void,
  show: boolean
}

const mapStateToProps = (state: AppState, ownProps: Props) => {
  const {active} = state.editor.data
  const {
    entity: activeEntity,
  } = active
  const {
    stop_id
  } = activeEntity
  const {timetableAdvancedModalState} = state.editor
  const {onClose, show, feedSource} = ownProps
  const project = findProjectByFeedSource(state.projects.all, feedSource.id)
  const timetableAdvancedModalStatus = timetableAdvancedModalState ? timetableAdvancedModalState.status : null
  return {
    stop_id,
    feedSource,
    project,
    timetableAdvancedModalState,
    timetableAdvancedModalStatus,
    onClose,
    show
  }
}

const mapDispatchToProps = {
  fetchTimetableAdvanced,
  resetStatusTimetableAdvanced
}

const ActiveTimetableAdvancedModal = connect(mapStateToProps, mapDispatchToProps)(TimetableAdvancedModal)

export default ActiveTimetableAdvancedModal
