// @flow

import { Browser } from 'leaflet'

import type {TileLayerProps, MapLayer} from '../../types'

const DEFAULT_MAP_ID = 'mapbox/outdoors-v11'

const OSM_BASE_URL = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
const OSM_ATTRIBUTION = '<a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'

const MAPBOX_ATTRIBUTION = process.env.MAPBOX_ATTRIBUTION || `<a href="https://www.mapbox.com/about/maps/" target="_blank">&copy; Mapbox &copy; OpenStreetMap</a> <a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a>`

/**
 * Default map layers for the GTFS editor. (Note: this is defined in the common
 * util file in order to keep all refs to mapbox IDs in a single file.)
 */
export const EDITOR_MAP_LAYERS: Array<MapLayer> = [
  {
    name: 'Streets',
    //id: 'mapbox/outdoors-v11',
    id: 'mapbox/streets-v11',
    attribution: MAPBOX_ATTRIBUTION
  },
  {
    name: 'Outdoors',
    id: 'mapbox/outdoors-v11'
  },

  // é preciso atualizar
/*   {
    name: 'OSM',
    id: 'osm/streets',
    url: OSM_BASE_URL,
    attribution: OSM_ATTRIBUTION
  }, */
  {
    name: 'Light',
    id: 'mapbox/light-v10',
    attribution: MAPBOX_ATTRIBUTION
  },
  {
    name: 'Dark',
    id: 'mapbox/dark-v10',
    attribution: MAPBOX_ATTRIBUTION
  },
  {
    name: 'Satellite',
    id: 'mapbox/satellite-v9',
    attribution: MAPBOX_ATTRIBUTION
  },
  {
    name: 'Satellite Streets',
    id: 'mapbox/satellite-streets-v11',
    attribution: MAPBOX_ATTRIBUTION
  }
]

/**
 * Get the default Mapbox tile URL used for use in a leaflet map. Optionally
 * takes a map ID (e.g., mapbox/outdoors-v11).
 */
// eslint-disable-next-line complexity
export function defaultTileLayerProps (mapId: ?string): TileLayerProps {
  // If no mapId is provided, default to id defined in env.yml or, ultimately,
  // fall back on default value.
  const id = mapId || process.env.MAPBOX_MAP_ID || DEFAULT_MAP_ID
  const MAPBOX_ACCESS_TOKEN = process.env.MAPBOX_ACCESS_TOKEN
  if (!MAPBOX_ACCESS_TOKEN && !MAP_BASE_URL) {
    throw new Error('One of Mapbox token or base url must be defined')
  }
  
  const layer = EDITOR_MAP_LAYERS.find(l => l.id === mapId) || EDITOR_MAP_LAYERS[0]
  const attribution = layer.attribution

  //const retina = window.retina || window.devicePixelRatio > 1 || Browser.retina
  const retina = false

  /* if (id != 'osm/streets'){
    console.log(MAP_BASE_URL)
    const MAP_BASE_URL = `https://api.mapbox.com/styles/v1/${id}/tiles/{z}/{x}/{y}${retina ? '@2x' : ''}?access_token=${MAPBOX_ACCESS_TOKEN}`
  }
  else{
    const MAP_BASE_URL = layer.url
  } */

  const MAP_BASE_URL = id != 'osm/streets' ? `https://api.mapbox.com/styles/v1/${id}/tiles/{z}/{x}/{y}${Browser.retina ? '@2x' : ''}?access_token=${MAPBOX_ACCESS_TOKEN}` : layer.url
   
  const url = MAP_BASE_URL
  const retinaProps = retina
    ? {tileSize: 512, zoomOffset: -1}
    : {}
  return {
    attribution,
    zoomOffset:-1,
    tileSize:512,
    url,
    maxZoom: 23,
    minZoom: 1
  }
}
