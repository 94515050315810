// @flow

import React, {PureComponent} from 'react'
import L from 'leaflet'
import {
  Pane,
  TileLayer,
  FeatureGroup,
  LayersControl,
  Polyline,
  Polygon,
  Marker,
  Tooltip,
  Label
} from 'react-leaflet'

import {getUserMetadataProperty} from '../../../common/util/user'
import {isExtensionEnabled} from '../../../common/util/config'
import {defaultTileLayerProps, EDITOR_MAP_LAYERS} from '../../../common/util/maps'
import StopLayer from '../../../scenario-editor/components/StopLayer'

import type {GtfsStop, MapLayer} from '../../../types'
import type {ManagerUserState} from '../../../types/reducers'
import * as editorActions from '../../actions/editor'

type Props = {
  feedSource: Feed,
  agency: Array<GtfsAgency>,
  getZoningShapeFile:  typeof tripPatternActions.getZoningShapeFile,
  getZoneStopsShapeFile:  typeof tripPatternActions.getZoneStopsShapeFile,
  stops: Array<GtfsStop>,
  tripPatterns: Array<any>,
  mapState: MapState,
  user: ManagerUserState,
  feedSourceId: String,
  sessionId: String,
  getStopsTimepoint: typeof editorActions.getStopsTimepoint,
  updateStopTimepoint: typeof tripPatternActions.updateStopTimepoint,
  timepointStops: any
}

type State = {
  forceUpdateTimepoints: boolean
}

type OverlayItem = {component: any, name: string}

export default class EditorMapLayersControl extends PureComponent<Props> {

  state = {
    forceUpdateTimepoints: false
  }

  componentWillReceiveProps (nextProps: Props) {
    if (this.props.timepointStops !== nextProps.timepointStops) {
      console.log("timepointStops foi atualizado!");

      this.setState({ forceUpdateTimepoints: true }, () => {
        setTimeout(() => {
          this.setState({ forceUpdateTimepoints: false });
          //console.log("Renderização atualizada: ", this.state.renderTimepoints);
        }, 100);
      });
    }

    const nextZoningsToFetch=nextProps.mapState.agencyZoningToFetch
    const nextAgencyTable=nextProps.agency
    // for each agency with agency_zoning_url
    nextAgencyTable.filter(t=>t.agency_zoning_url).map(agency=>{
      const mapStateZoningsToFetchFiltered = nextZoningsToFetch.filter(t=>t===agency.id)
      if (mapStateZoningsToFetchFiltered.length!==0) {
        // needs to update shape at AgencyZoning object
        this.props.getZoningShapeFile(this.props.feedSource,agency.id,agency.agency_zoning_url)
      }
    })

    const nextZoneStopsToFetch=nextProps.mapState.agencyZoneStopsToFetch
    // for each agency with agency_zonestops_url
    nextAgencyTable.filter(t=>t.agency_zonestops_url).map(agency=>{
      const mapStateZoneStopsToFetchFiltered = nextZoneStopsToFetch.filter(t=>t===agency.id)
      if (mapStateZoneStopsToFetchFiltered.length!==0) {
        // needs to update shape at AgencyZoning object
        this.props.getZoneStopsShapeFile(this.props.feedSource,agency.id,agency.agency_zonestops_url)
      }
    })
  }

  onZoningAdd = (e,id) => {
    const agencyZonings=this.props.mapState.agencyZonings
    const agencyTable=this.props.agency
    agencyTable.filter(t=>t.agency_zoning_url).map(agency=>{
      const mapStateAgencyZoningsFiltered = agencyZonings.filter(t=>t.entityId===agency.id)
      if (mapStateAgencyZoningsFiltered.length===0) {
          // needs to add AgencyZoning object to mapState
          this.props.getZoningShapeFile(this.props.feedSource,agency.id,agency.agency_zoning_url)
      }
    })
  }

  onZoneStopsLayerAdd = (e,id) => {
    const agencyZoneStops=this.props.mapState.agencyZoneStops
    const agencyTable=this.props.agency
    agencyTable.filter(t=>t.agency_zonestops_url).map(agency=>{
      const mapStateAgencyZoneStopsFiltered = agencyZoneStops.filter(t=>t.entityId===agency.id)
      if (mapStateAgencyZoneStopsFiltered.length===0) {
          // needs to add AgencyZoning object to mapState
          this.props.getZoneStopsShapeFile(this.props.feedSource,agency.id,agency.agency_zonestops_url)
      }
    })
  }

  render () {
    const { forceUpdateTimepoints } = this.state;
    const canvas = L.canvas()
    const { tripPatterns, stops, user, agency: agencyTable, mapState, feedSourceId,
      sessionId, getStopsTimepoint, timepointStops } = this.props
    const { BaseLayer, Overlay } = LayersControl
    const OVERLAYS: Array<OverlayItem> = [
      {
        name: 'Route alignments',
        component: (
          <FeatureGroup>
            {/* {tripPatterns
              ? tripPatterns.map((tp) => {
                //console.log("TP: ", tp)
                if (!tp.latLngs) return null
                return (
                  <Polyline
                    color='#4B0082'
                    //interactive={false}
                    key={tp.id}
                    positions={tp.latLngs}
                    renderer={canvas}
                    weight={2}>
                    <Tooltip sticky>
                      <span>{tp.name} ({tp.route_id})</span>
                    </Tooltip>
                  </Polyline>
                )
              })
              : null
            } */}
            {tripPatterns && tripPatterns
                .filter(tp => Array.isArray(tp.latLngs) && tp.latLngs.length > 0)
                .map((tp) => (
                    <Polyline
                        color='#4B0082'
                        key={tp.id}
                        positions={tp.latLngs}
                        renderer={canvas}
                        weight={2}
                    >
                        <Tooltip sticky>
                            <span>{tp.name} ({tp.route_id})</span>
                        </Tooltip>
                    </Polyline>
                ))
            }
          </FeatureGroup>
        )
      },
      /* {
        name: 'Stop locations',
        component: <StopLayer key='stop-layer' stops={stops} />
      } */
      {
        name: 'Stop locations',
        component: (
          <Pane name="stops-layer" style={{ zIndex: 350 }}>
            <StopLayer key='stops-layer' stops={stops} currentZoom={mapState.zoom} withNames={false} getData={false} feedSourceId={feedSourceId} sessionId={sessionId} isTimepoint={false}/>
          </Pane>
        )
      },
      {
        name: 'Stop locations (with names)',
        component: (
          <Pane name="stops-layer-names" style={{ zIndex: 350 }}>
            <StopLayer key='stops-layer-names' stops={stops} currentZoom={mapState.zoom} withNames={true} getData={false} feedSourceId={feedSourceId} sessionId={sessionId} isTimepoint={false}/>
          </Pane>
        )
      },
      {
        name: 'Stop locations (timepoint=1)',
        component: (
          <Pane name="stops-timepoint" style={{ zIndex: 350 }}>
            <StopLayer key='stop-layer-timepoint' stops={timepointStops} currentZoom={mapState.zoom} withNames={false} getData={true} feedSourceId={feedSourceId} sessionId={sessionId} getStopsTimepoint={getStopsTimepoint} isTimepoint={true}/>
          </Pane>
        )
      },
      {
        name: 'Stop locations (timepoint=1 and names)',
        component: (
          <Pane name="stops-timepoint-names" style={{ zIndex: 350 }}>
            <StopLayer key='stop-layer-timepoint-names' stops={timepointStops} currentZoom={mapState.zoom} withNames={true} getData={true} feedSourceId={feedSourceId} sessionId={sessionId} getStopsTimepoint={getStopsTimepoint} isTimepoint={true} forceUpdateTimepoints={forceUpdateTimepoints} />
          </Pane>
        ) 
      },
    ]
    const agencyZonings = mapState.agencyZonings
    const agencyZoneStops = mapState.agencyZoneStops
    if (agencyTable) {
      // devolver agencies em ordem alfabética (agency_name)
      agencyTable.sort(function (a, b) {
        if (a.agency_name && b.agency_name){
          return a.agency_name.localeCompare(b.agency_name);
        }
      }).map((agency) => {        
        // agencyZonings
        if (agency.agency_zoning_url) {
          const agencyZoningsFiltered=agencyZonings.filter(t=>t.entityId===agency.id)
          if (agencyZoningsFiltered.length) {
            agencyZoningsFiltered.map(agencyZoning=>{
              OVERLAYS.push({
                name: 'Zoning shapes for '+agency.agency_name,
                component: (
                  <Pane name="overlays2" style={{ zIndex: 350 }}>
                    <FeatureGroup 
                      onAdd={(e) => this.onZoningAdd(e,agency.id)}
                    >
                      {agencyZoning && agencyZoning.shape
                        ? agencyZoning.shape.map((zonePolygon) => {
                          if (!zonePolygon.data) return null
                          return (
                            <Polygon
                              key={zonePolygon.key+(mapState.zoom>12?'p':'z')}  
                              color='purple'
                              weight={1}
                              fillOpacity={0.03}
                              positions={zonePolygon.data}>
                                <Tooltip permanent={mapState.zoom>12}><div style={{color: 'purple', fontWeight: 'bold'}}>{zonePolygon.name}</div></Tooltip>
                            </Polygon>
                          )
                        })
                        : null
                      }
                    </FeatureGroup>
                  </Pane>
                )
              })
            })
          } else {
            OVERLAYS.push({
              name: 'Zoning shapes for '+agency.agency_name,
              component: (
                <Pane name="overlays2" style={{ zIndex: 350 }}>
                  <FeatureGroup onAdd={(e) => this.onZoningAdd(e,agency.id)}>
                  </FeatureGroup>
                </Pane>
              )
            })
          }
        }
        // agencyZoneStops       
        if (agency.agency_zonestops_url) {
          const agencyZoneStopsFiltered=agencyZoneStops.filter(t=>t.entityId===agency.id)
          if (agencyZoneStopsFiltered.length) {
            agencyZoneStopsFiltered.map(agencyZoning=>{
              OVERLAYS.push({
                name: 'Zone stops shapes for '+agency.agency_name,
                component: (
                  <Pane name="overlays2" style={{ zIndex: 350 }}>
                    <FeatureGroup 
                      onAdd={(e) => this.onZoneStopsLayerAdd(e,agency.id)}
                    >
                      {agencyZoning && agencyZoning.shape
                        ? agencyZoning.shape.map((zoneStopsPoints) => {
                          if (!zoneStopsPoints.coords) return null
                          return (
                            <Marker
                              key={zoneStopsPoints.key}  
                              title={zoneStopsPoints.name}
                              position={zoneStopsPoints.coords}>
                            </Marker>
                          )
                        })
                        : null
                      }
                    </FeatureGroup>
                  </Pane>
                )
              })
            })
          } else {
            OVERLAYS.push({
              name: 'Zone stops shapes for '+agency.agency_name,
              component: (
                <Pane name="overlays2" style={{ zIndex: 350 }}>
                  <FeatureGroup onAdd={(e) => this.onZoneStopsLayerAdd(e,agency.id)}>
                  </FeatureGroup>
                </Pane>
              )
            })
          }
        }
      })
    }

    const activeMapLayerIndex: number = EDITOR_MAP_LAYERS.findIndex(
      (l: MapLayer) => l.id === getUserMetadataProperty(user.profile, 'editor.map_id')
    )
    return (
      <LayersControl position='topleft'>
        {EDITOR_MAP_LAYERS.map((layer: MapLayer, index: number) => (
          <BaseLayer
            name={layer.name}
            key={layer.id}
            checked={activeMapLayerIndex !== -1 ? index === activeMapLayerIndex : index === 0}>
            <TileLayer {...defaultTileLayerProps(layer.id)} />
          </BaseLayer>
        ))}
        {isExtensionEnabled('nysdot') &&
          <Overlay
            name='Route layer'
            key='route-layer'>
            <TileLayer
              url={`https://s3.amazonaws.com/datatools-nysdot/tiles/{z}_{x}_{y}${L.Browser.retina ? '@2x' : ''}.png`} />
          </Overlay>
        }
        {OVERLAYS.map((overlay: OverlayItem, i: number) => (
          <Overlay
            name={overlay.name}
            key={i.toString()+overlay.name}>
            {overlay.component}
          </Overlay>
        ))}
      </LayersControl>
    )
  }
}
