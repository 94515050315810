// @flow

import { connect } from 'react-redux'

import {
  fetchBusStopSign,
  resetStatusBusStopSign
} from '../actions/trip'
import BusStopSignModal from '../components/BusStopSignModal'
import {findProjectByFeedSource} from '../../manager/util'

import type {AppState, RouteParams} from '../../types/reducers'

export type Props = {
  activeEntityId: number,
  feedSource: Feed,
  project: Project,
  onClose: () => void,
  show: boolean
}

const mapStateToProps = (state: AppState, ownProps: Props) => {
  const {active} = state.editor.data
  const {
    entity: activeEntity,
  } = active
  const {
    stop_id
  } = activeEntity
  const {busStopSignModalState} = state.editor
  const {onClose, show, feedSource} = ownProps
  const project = findProjectByFeedSource(state.projects.all, feedSource.id)
  const busStopSignModalStatus = busStopSignModalState ? busStopSignModalState.status : null
  return {
    stop_id,
    feedSource,
    project,
    busStopSignModalState,
    busStopSignModalStatus,
    onClose,
    show
  }
}

const mapDispatchToProps = {
  fetchBusStopSign,
  resetStatusBusStopSign
}

const ActiveBusStopSignModal = connect(mapStateToProps, mapDispatchToProps)(BusStopSignModal)

export default ActiveBusStopSignModal
