// @flow

import React, {Component} from 'react'
import {
  Radio,
  FormGroup,
  Modal, 
  Button,
  Checkbox,
  DropdownButton,
  ButtonGroup,
  Glyphicon,
  MenuItem,
  ProgressBar
} from 'react-bootstrap'

import {getComponentMessages} from '../util/config'
import * as commonActions from '../actions'
import {versionsLastUpdatedComparator} from '../../manager/util/version'

type Props = ContainerProps & {
  cgiJobProgress: typeof commonActions.cgiJobProgress,
}

type State = {
  schedulesType: Number,
  runJob?: (any, any, any) => boolean,
  started: boolean,
  running: boolean,
  compare: boolean,
  compareVersionIndex: number,
  versionSummaries: any,
  editor: boolean,
  feedVersionIndex: number,
  showModal: boolean,
  download_key: any
}

export default class GenSchedulesModal extends Component<Props, State> {
  messages = getComponentMessages('FeedVersionNavigator')

  componentWillMount () {
    // Set initial state.
    this.setState({
      schedulesType: 1,
      started: false,
      running: false,
      showModal: false
    })
  }

  componentWillReceiveProps (newProps: Props) {
    const {cgiJobStatus} = newProps
    if (cgiJobStatus===undefined) {
      return
    }
    if (!this.state.running 
      && cgiJobStatus.job_id!==undefined
      && this.state.runJob!==undefined) {
      let compareVersionId = null
      if (!this.state.editor && this.state.compare) {
        compareVersionId = this.state.versionSummaries[this.state.compareVersionIndex-1].id
      }
      this.setState({
        running: this.state.runJob(cgiJobStatus.job_id, this.state.download_key, compareVersionId)
      })
    }
    if (cgiJobStatus.total_lines===null) {
      this.setState({
        started: false,
        running: false
      })
      this.close()
    }
  }

  close = () => {
    this.setState({showModal: false})
  }

  open (props: Props) {
    const {
      feedSource,
      versionSummaries,
      feedVersionIndex
    } = this.props
    this.setState({
      showModal: true,
      feedVersionIndex: feedVersionIndex,
      ...props
    })
    if (props.editor) return
    const hasVersions = versionSummaries && versionSummaries.length > 1
    if (hasVersions && versionSummaries) {
      let compareVersionIndex = versionSummaries.length
      if (compareVersionIndex==feedVersionIndex) {
        compareVersionIndex--;
        if (compareVersionIndex<1) 
          compareVersionIndex=versionSummaries.length
      }
      this.setState({
        compareVersionIndex: compareVersionIndex,
        versionSummaries: versionSummaries
      })
    }
  }

  _generate = () => {
    this.setState({started: true})
    this.props.cgiJobProgress(null)
  }

  _onCheckboxChange = (evt: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({compare: !evt.target.checked})
  }

  _decrementVersion = () => {
    let newVersionIndex = this.state.compareVersionIndex - 1
    if (newVersionIndex==this.state.feedVersionIndex) newVersionIndex--
    this.setState({compareVersionIndex: newVersionIndex})
  }

  _incrementVersion = () => {
    let newVersionIndex = this.state.compareVersionIndex + 1
    if (newVersionIndex==this.state.feedVersionIndex) newVersionIndex++
    this.setState({compareVersionIndex: newVersionIndex})
  }

  _onSelectVersion = (index: number) => {
    if (index !== this.state.compareVersionIndex) {
      this.setState({compareVersionIndex: index})
    }
  }
  render () {
    const {Body, Footer, Header, Title} = Modal
    const {schedulesType, started, title, notes, editor, compare, download_key, feedVersionIndex, compareVersionIndex, versionSummaries} = this.state
    const {
      cgiJobStatus
    } = this.props
    const hasVersions = versionSummaries && versionSummaries.length > 1
    return (
      <Modal
        show={this.state.showModal}
        onHide={this.close}
        backdrop="static"
        keyboard={false}
      >
        <Header>
          <Title>{title}</Title>
        </Header>
        <Body>
          {notes}
          {(!editor && (download_key==='1' || download_key==='4') && hasVersions)
            ?<Checkbox
              checked={!compare}
              disabled={started}
              onChange={this._onCheckboxChange}>
              {download_key==='1'
                ?'All routes'
                :'All stops'
              }
            </Checkbox>
            :null
          }
          {(!editor && (download_key==='1' || download_key==='4') && compare && hasVersions && !(typeof feedVersionIndex === 'undefined'))
            ? <p>Choose version to compare with version {feedVersionIndex}, <strong>{versionSummaries[feedVersionIndex-1].name}</strong></p>
            : null
          }
          {(!editor && (download_key==='1' || download_key==='4') && compare && hasVersions && !(typeof feedVersionIndex === 'undefined'))
            ? <ButtonGroup> {/* Version Navigation/Selection Widget */}
              {/* Previous Version Button */}
              <Button
                data-test-id='decrement-feed-version-button'
                disabled={!hasVersions || !versionSummaries[compareVersionIndex - 2] || (compareVersionIndex==2 && feedVersionIndex==1)}
                href='#'
                onClick={this._decrementVersion}>
                <Glyphicon glyph='arrow-left' />
              </Button>

              {/* Version Selector Dropdown */}
              <DropdownButton
                href='#'
                id='versionSelector'
                title={`${this.messages('version')} ${compareVersionIndex} ${this.messages('of')} ${versionSummaries.length - 1}`}
                onSelect={this._onSelectVersion}>
                {versionSummaries.map((version, k) => {
                  k = k + 1
                  return (feedVersionIndex==k)?null:<MenuItem key={k} eventKey={k}>{k}. {version.name}</MenuItem>
                })}
              </DropdownButton>
              {/* Next Version Button */}
              <Button href='#'
                disabled={!hasVersions || !versionSummaries[compareVersionIndex] || (compareVersionIndex==versionSummaries.length-1 && feedVersionIndex==versionSummaries.length)}
                onClick={this._incrementVersion}>
                <Glyphicon glyph='arrow-right' />
              </Button>
            </ButtonGroup>
            :null
          }

          {cgiJobStatus && started
            ?<ProgressBar active min={0} max={cgiJobStatus.total_lines} now={cgiJobStatus.processed_lines} />
            :null
          }
          {cgiJobStatus && started
            ?<p>{cgiJobStatus.processed_lines} / {cgiJobStatus.total_lines}</p>
            :null
          }
        </Body>
        <Footer>
          <Button
            data-test-id='status-modal-export-button'
            onClick={this._generate}
            disabled={started}
          >
            Export
          </Button>
          <Button
            data-test-id='status-modal-close-button'
            onClick={this.close}
            disabled={started}
          >
            Close
          </Button>
        </Footer>
      </Modal>
    )
  }
}
