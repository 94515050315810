import React, { useState, useEffect } from 'react';

const CsvTable = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchCsv = async () => {
      const response = await fetch('https://docs.google.com/spreadsheets/d/e/2PACX-1vQj5P7gvbhLLk2sRhOwFZLINqlTfb6GWlGRPRsyN7uvEaq2r1_YMDo3ZrnP2r8nLBpxW9KcrUrN973N/pub?gid=0&single=true&output=csv');
      const csvText = await response.text();

      // Split the CSV text by new lines
      const rows = csvText.trim().split('\n');

      // Split each row by commas to get the individual cells
      const parsedData = rows.map(row => row.split(','));

      setData(parsedData);
    };

    fetchCsv();
  }, []);

  const handleNotificationClick = () => {
    alert('Notification clicked!');
  };

  return (
    <div className='warnings-trenmo'>
      <p className='custom-title'>Notifications - TRENMO</p>
      <table>
        <thead>
          <tr>
            <th style={{width: '20%'}}>Date</th>
            <th style={{width: '80%'}}>Description</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td style={{width: '20%'}}>{row[0]}</td>
              <td style={{width: '80%'}} className='td-clickable' onClick={handleNotificationClick}>
                <div className='notif-wrapper'>
                  <i className="fa fa-info-circle"></i>
                  <span>{row[1]}</span>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CsvTable;
