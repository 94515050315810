// @flow

import { connect } from 'react-redux'

import {
  fetchTimetable
} from '../actions/trip'
import {setActiveGtfsEntity} from '../actions/active'
import TimetableModal from '../components/timetable/TimetableModal'
import {findProjectByFeedSource} from '../../manager/util'

import type {AppState, RouteParams} from '../../types/reducers'

export type Props = {
  routeParams: RouteParams,
  onClose: () => void,
  show: boolean
}

const mapStateToProps = (state: AppState, ownProps: Props) => {
  //console.log('ownProps:', ownProps);
  const {active, tables} = state.editor.data
  const {
    entityId: activeEntityId
  } = active
  const {timetableModalState} = state.editor
  const {onClose, show} = ownProps
  const {feedSourceId} = ownProps.routeParams
  const project = findProjectByFeedSource(state.projects.all, feedSourceId)
  const feedSource = project && project.feedSources && project.feedSources.find(fs => fs.id === feedSourceId)
  const timetableModalStatus = timetableModalState ? timetableModalState.status : null
  const currentTimepointStops = state.editor.data.tables.stops_timepoint
  return {
    activeEntityId,
    feedSource,
    tableData: tables,
    timetableModalState,
    timetableModalStatus,
    onClose,
    show
  }
}

const mapDispatchToProps = {
  fetchTimetable,
  setActiveGtfsEntity
}

const ActiveTimetableModal = connect(mapStateToProps, mapDispatchToProps)(TimetableModal)

export default ActiveTimetableModal
