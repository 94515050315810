// @flow

import update from 'react-addons-update'
import clone from 'lodash/cloneDeep'

// import { sortAndFilterTrips } from '../util'

import type { Action } from '../../types/actions'
import type { BusStopSignModalState } from '../../types/reducers'

export const defaultState = {
  status: {
    fetched: false,
    error: false,
    fetching: false
  }
}

/* eslint-disable complexity */
const busStopSignModalState = (state: BusStopSignModalState = defaultState, action: Action): BusStopSignModalState => {
  switch (action.type) {
    case 'RECEIVE_BUS_STOP_SIGN':
      const {
        url,
        type,
        filename
      } = action.payload
      const {url: prevUrl} = state
      if (prevUrl!==undefined && prevUrl!==null) {
        window.URL.revokeObjectURL(url);
      }
      return update(state, {
        url: { $set: url},
        filename: {$set: filename },
        type: {$set: type },
        status: {
          fetched: { $set: true },
          error: { $set: false },
          fetching: { $set: false }
        }
      })
      case 'REQUESTING_BUS_STOP_SIGN':
        return update(state, {
          url: { $set: null},
          type: {$set: null },
          filename: {$set: null },
          status: {
            fetched: { $set: false },
            error: { $set: false },
            fetching: { $set: true }
          }
        })
      case 'RESET_BUS_STOP_SIGN':
        return update(state, {
          url: { $set: null},
          type: {$set: null },
          filename: {$set: null },
          status: {
            fetched: { $set: false },
            error: { $set: false },
            fetching: { $set: false }
          }
        })
      default:
      return state
  }
}

export default busStopSignModalState
