// @flow

import update from 'react-addons-update'
import clone from 'lodash/cloneDeep'

// import { sortAndFilterTrips } from '../util'

import type { Action } from '../../types/actions'
import type { CalendarEditorState } from '../../types/reducers'

export const defaultState = {
  calendar: null,
  exceptionsAdded: [],
  exceptionsRemoved: [],
  status: {
    fetched: false,
    error: false,
    fetching: false,
    saving: false
  }
}

/* eslint-disable complexity */
const calendar = (state: CalendarEditorState = defaultState, action: Action): CalendarEditorState => {
  switch (action.type) {
    case 'RECEIVE_SERVICE_CALENDAR':
      const {
        calendar,
        exceptionsAdded,
        exceptionsRemoved
      } = action.payload
      return update(state, {
        calendar: { $set: calendar },
        exceptionsAdded: { $set: exceptionsAdded },
        exceptionsRemoved: { $set: exceptionsRemoved },
        status: {
          fetched: { $set: true },
          error: { $set: false },
          fetching: { $set: false }
        }
      })
    case 'REQUESTING_SERVICE_CALENDAR':
      return update(state, {
        status: {
          fetched: { $set: false },
          error: { $set: false },
          fetching: { $set: true }
        }
      })
    case 'REQUESTING_SCHEDULE_EXCEPTIONS_TO_ADD':
      return update(state, {
        status: {
          fetched: { $set: false },
          error: { $set: false },
          fetching: { $set: true },
          saving: { $set: true }
        }
      })
    case 'SAVING_SERVICE_CALENDAR':
      return update(state, {
        status: {
          fetched: { $set: false },
          error: { $set: false },
          fetching: { $set: false },
          saving: { $set: true }
        }
      })
    case 'SAVED_SERVICE_CALENDAR':
      return update(state, {
        status: {
          fetched: { $set: false },
          error: { $set: false },
          fetching: { $set: false },
          saving: { $set: false }
        }
      })
    default:
      return state
  }
}

export default calendar
