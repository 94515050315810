// @flow

import React, { Component } from 'react'
import { 
  Modal, 
  Button, 
  Table, 
  Nav, 
  NavItem, 
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap'
import moment, { months } from 'moment'

import * as timetableActions from '../../actions/trip'
import {setActiveGtfsEntity} from '../../actions/active'

import type { Feed, FetchStatus } from '../../../types'
import type { EditorTables, TimetableModalState } from '../../../types/reducers'

type Props = {
  activeEntityId: number,
  feedSource: Feed,
  tableData: EditorTables,
  timetableModalState: TimetableModalState,
  timetableModalStatus: FetchStatus,
  fetchTimetable: typeof timetableActions.fetchTimetable,
  setActiveGtfsEntity: typeof setActiveGtfsEntity,
  timepointStops: any,
  onClose: () => void,
  show: boolean
}

type State = {
  showModal: boolean,
  activeDirection: number,
  activeFilter: number
}

export default class TimetableModal extends Component<Props, State> {
  state = {
    showModal: this.props.show,
    activeDirection: 0,
    activeFilter: 0
  }

  /**
   * Handle re-fetching calendar if stated to refresh.
   */
  componentWillReceiveProps(nextProps: Props) {
    const { 
      show: previousShow,
      timetableModalState: previoustimetableModalState
    } = this.props;
    const {
      feedSource,
      timetableModalState,
      activeEntityId,
      show
    } = nextProps;
    const showChanged = previousShow !== show;
    if (feedSource && activeEntityId && showChanged && show){
      // Only fetch trips if the feed, pattern, and schedule are present.
      this.setState({ activeDirection: 0 });
      this.setState({ activeFilter: 0 });
      this.props.fetchTimetable(feedSource.id, timetableModalState, activeEntityId);
    }

    if (this.props.timepointStops !== nextProps.timepointStops) {
      // do something
    }

    if (this.props.timetableModalState !== nextProps.timetableModalState) {
      
    }
  }

  _close = () => {
    const { onClose } = this.props
    onClose && onClose()
  }

  _onSelectDirection = (event, value) => {
    this.setState({ activeDirection: value });
  }

  _navigateToTrip = (event: string, value: string) => {
    const {
      setActiveGtfsEntity, 
      feedSource, 
      activeEntityId, 
      onClose,
      timetableModalState: data
    } = this.props
    const trip = data.route.trips.find((trip) => trip.trip_id === value)
    const tripPattern = data.route.tripPatterns.find((pattern) => pattern.pattern_id === trip.pattern_id)
    setActiveGtfsEntity(
      feedSource.id, 
      'route',
      activeEntityId,
      'trippattern',
      tripPattern.id,
      'timetable',
      trip.service_id)
    onClose && onClose()
  }

  _onSelectFilter = (event, value) => {
    this.setState({ activeFilter: value });
  }

  render() {
    const {
      timetableModalState: data
    } = this.props;
    var activeDirection = this.state.activeDirection;
    var activeFilter = this.state.activeFilter
    var directions=[];
    var colunms=[];
    var lines=[];
    var trips=[];
    var calendar=[];
    var tripPatterns=[];
    var route_short_name = '???...';
    var route_long_name = '???...';
    var route=null
    if (data && data.stop_times_ordered) {
      directions = data.stop_times_ordered.map(item => item.i_direction)
          .filter((value, index, self) => self.indexOf(value) === index);
      if (directions.length==1) {
        activeDirection=directions[0];
      }
      colunms = data.stop_times_ordered
        .filter((value) => value.i_direction === activeDirection)
        .sort((a, b) => (a.col_num-b.col_num))
        .map(item => item.trip_id)
        .filter((value, index, self) => self.indexOf(value) === index);
      var last_stop_sequence=0;
      var column_num=0;
      var line_index=-1;
      for(var i=0;i<data.stop_times_ordered.length;i++){
        const item=data.stop_times_ordered[i];
        if (item.i_direction==activeDirection){
          if (item.stop_sequence!=last_stop_sequence){
            var stop_name='???...';
            var stop=null;
            if (data.stops) {
              const stops=data.stops.filter((stop) => stop.stop_id === item.stop_id);
              if (stops.length>0) {
                stop_name = stops[0].stop_name;
                stop = stops[0];
              }
            }
            lines.push({
              lineNumber: item.stop_sequence,
              stop_name: stop_name,
              stop: stop,
              visible: (activeFilter==0),
              cells: []
            });
            line_index++;
            column_num=0;
          }
          const cell={
            time: item.arrival_time || item.departure_time
              ?moment()
                .set('hour', 0)
                .set('minute', 0)
                .set('second', 0)
                .set('millisecond', 0)
                .add(item.arrival_time
                  ?item.arrival_time
                  :item.departure_time
                , 'seconds')
              :null,
            arrival_time: item.arrival_time
              ?moment()
                .set('hour', 0)
                .set('minute', 0)
                .set('second', 0)
                .set('millisecond', 0)
                .add(item.arrival_time, 'seconds')
              :null,
              departure_time: item.departure_time
              ?moment()
                .set('hour', 0)
                .set('minute', 0)
                .set('second', 0)
                .set('millisecond', 0)
                .add(item.departure_time, 'seconds')
              :null,
            stop_times_item: item,
            //empty: item.timepoint == 0 && activeFilter==1 ? true : false,
            empty: false,
            isTimepoint: item.timepoint
          }
          // ESTAVA AQUI
          
          while(++column_num<item.col_num){
            lines[line_index].cells.push({
              empty: true
            });  
          }
          lines[line_index].cells.push(cell);
          last_stop_sequence=item.stop_sequence;

          if (activeFilter==1)  {
            //lines[line_index].visible = (item.timepoint==null || item.timepoint==1);
            lines[line_index].visible = lines[line_index].cells.some(cell => cell.isTimepoint)
            //lines[line_index].visible = item.timepoint==1;

            console.log(`I = ${i}; TRIP ID: ${item.trip_id}; STOP NAME: ${lines[line_index].stop_name}; TimePoint: ${item.timepoint}; Line Number Table: ${lines[line_index].lineNumber}`)
            //lines[line_index].
          }
        }
      }
      for(var i=0;i<lines.length;i++){
        while(lines[i].cells.length<colunms.length){
          lines[i].cells.push({
            empty: true
          })
        }

        //console.log(`STOP NAME: ${lines[i].stop_name}; Line Number: ${lines[i].lineNumber}`)
        
      }
      if (activeFilter==2)  {
        for(var c=0;c<colunms.length;c++){
          for(var i=0;i<lines.length;i++){
            if (!lines[i].cells[c].empty) {
              lines[i].visible=true;
              break;
            }
          }
          for(var i=lines.length-1;i>=0;i--){
            if (!lines[i].cells[c].empty) {
              lines[i].visible=true;
              break;
            }
          }
        }  
      }
    }
    if (data && data.route && data.route.trips) {
      trips = data.route.trips;
    }
    if (data && data.route && data.route.tripPatterns) {
      tripPatterns = data.route.tripPatterns;
    }
    if (data && data.route) {
      route_short_name = data.route.route_short_name;
      route_long_name = data.route.route_long_name;
      route = data.route
    }
    if (data && data.calendar) {
      calendar = data.calendar;
    }
    const { Body, Footer, Header, Title } = Modal
    return (
      <Modal
        //bsSize="large"
        dialogClassName='timetable-modal'
        show={this.props.show}
        onHide={this._close}>
        <Header>
          <Title>            
            <OverlayTrigger 
              id={'timetable-route'}
              placement='bottom'                      
              delayShow={400}
              overlay={
                <Tooltip
                  id={'timetable-route-tooltip'}
                >
                  <div className='text-left'>                                    
                    <center>routes.txt</center>
                    <hr style={{marginTop: '2px', marginBottom: '2px', height: '3px'}} />
                    agency_id: <strong>
                    {(route && route.agency_id !== undefined && route.agency_id != null)
                      ?route.agency_id
                      :'n/a'
                    }
                    </strong>
                    <br/>
                    route_desc: <strong>
                    {(route && route.route_desc !== undefined && route.route_desc != null)
                      ?route.route_desc
                      :'n/a'
                    }
                    </strong>
                    <br/>
                    route_type: <strong>
                    {(route && route.route_type !== undefined && route.route_type != null)
                      ?route.route_type
                      :'n/a'
                    }
                    </strong>
                    <br/>
                    route_url: <strong>
                    {(route && route.route_url !== undefined && route.route_url != null)
                      ?route.route_url
                      :'n/a'
                    }
                    </strong>
                    <br/>
                    route_color: <strong>
                    {(route && route.route_color !== undefined && route.route_color != null)
                      ?route.route_color
                      :'n/a'
                    }
                    </strong>
                    <br/>
                    route_text_color: <strong>
                    {(route && route.route_text_color !== undefined && route.route_text_color != null)
                      ?route.route_text_color
                      :'n/a'
                    }
                    </strong>
                    <br/>
                    route_sort_order: <strong>
                    {(route && route.route_sort_order !== undefined && route.route_sort_order != null)
                      ?route.route_sort_order
                      :'n/a'
                    }
                    </strong>
                  </div>
                </Tooltip>
              }
            >
              <div
                className='text-center'
                style={{
                  verticalAlign: 'middle',
                  display: 'inline-block'
                }}
              >
                <strong>{route_short_name}</strong>&nbsp;-&nbsp;{route_long_name}&nbsp;
              </div>
            </OverlayTrigger>
            <div
              className='pull-right'
            >
              <Nav
                className='small'
                bsStyle='pills'
                activeKey={activeDirection}>
                {directions.map((direction) => {
                  return (
                    <NavItem
                      eventKey={direction}
                      key={direction}
                      onClick={e => this._onSelectDirection(e, direction)}>
                      {direction==0?'Outbound (0)':'Inbound (1)'}
                    </NavItem>
                  )
                })}
              </Nav>
            </div>
          </Title>
        </Header>
        <Body
          style={{
            fontSize: 'small', 
            overflow: 'auto',
            maxHeight: '80vh',
            padding: '0px',
            background: 'white',            
            textAlign: 'center'
          }}
        >
        <Table 
          striped 
          bordered 
          condensed 
          hover
          className='small text-center sticky-table'
        >
          <thead>
          <tr>
            <th 
              style={{padding: '2px'}}
              className='text-left sticky-th-top-left'
            >stop_name</th>
            <th 
              style={{padding: '2px'}}
              className='text-center sticky-th-top'
            >#</th>
              {colunms.map((trip_id) => {
                return (
                  <th
                    key={trip_id}
                    className='text-center sticky-th-top'
                    style={{padding: '2px', cursor: 'pointer'}}
                    onClick={e => this._navigateToTrip(e, trip_id)}
                  >
                    {trips.filter((value) => value.trip_id === trip_id).map((trip) => {
                      return (
                        <OverlayTrigger 
                          key={trip.trip_id} 
                          id={'timetable-col-header-'+trip_id}
                          placement='left'                      
                          delayShow={400}
                          overlay={
                            <Tooltip
                              id={'timetable-col-header-tooltip-'+trip_id}
                            >
                              <div className='text-left'>
                                calendar_name: <strong>
                                {calendar.filter((value) => value.service_id === trip.service_id).map((cal) => {
                                    return cal.description
                                })}
                                </strong>
                                <br/>
                                pattern_name: <strong>
                                {tripPatterns.filter((value) => value.pattern_id === trip.pattern_id).map((pattern) => {
                                    return pattern.name
                                })}
                                </strong>                                 
                                <hr style={{marginTop: '2px', marginBottom: '2px', height: '3px'}} />
                                <center>trips.txt</center>
                                <hr style={{marginTop: '2px', marginBottom: '2px', height: '3px'}} />
                                trip_id: <strong>
                                {(trip.trip_id !== undefined && trip.trip_id != null)
                                  ?trip.trip_id
                                  :'n/a'
                                }
                                </strong>
                                <br/>
                                service_id: <strong>
                                {(trip.trip_id !== undefined && trip.service_id != null)
                                  ?trip.service_id
                                  :'n/a'
                                }
                                </strong>
                                <br/>
                                trip_headsign: <strong>
                                {(trip.trip_headsign !== undefined && trip.trip_headsign != null)
                                  ?trip.trip_headsign
                                  :'n/a'
                                }
                                </strong>
                                <br/>
                                trip_short_name: <strong>
                                {(trip.trip_short_name !== undefined && trip.trip_short_name != null)
                                  ?trip.trip_short_name
                                  :'n/a'
                                }
                                </strong>
                                <br/>
                                block_id: <strong>
                                {(trip.block_id !== undefined && trip.block_id != null)
                                  ?trip.block_id
                                  :'n/a'
                                }
                                </strong>
                                <br/>
                                shape_id: <strong>
                                {(trip.shape_id !== undefined && trip.shape_id != null)
                                  ?trip.shape_id
                                  :'n/a'
                                }
                                </strong>
                                <br/>
                                wheelchair_accessible: <strong>
                                {(trip.wheelchair_accessible !== undefined && trip.wheelchair_accessible != null)
                                  ?trip.wheelchair_accessible
                                  :'n/a'
                                }
                                </strong>
                                <br/>
                                bikes_allowed: <strong>
                                {(trip.bikes_allowed !== undefined && trip.bikes_allowed != null)
                                  ?trip.bikes_allowed
                                  :'n/a'
                                }
                                </strong>
                              </div>
                            </Tooltip>
                        }
                        >
                          <div>
                            {trip.service_id.split('-')[0]}
                            <hr style={{marginTop: '2px', marginBottom: '2px', height: '3px'}} />
                            {trip.service_id.split('-')[1]}
                          </div>
                        </OverlayTrigger>
                      )
                    })}
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {lines.filter((value) => value.visible).map((line, line_index) => {
              const stop = line.stop
              return (
                <tr
                  key={line.lineNumber}
                >
                  <OverlayTrigger 
                    id={'timetable-row-header-'+line.lineNumber}
                    placement='right'                      
                    delayShow={400}
                    overlay={
                      <Tooltip
                        id={'timetable-row-header-tooltip-'+line.lineNumber}
                      >
                        <div className='text-left'>                                    
                          <center>stops.txt</center>
                          <hr style={{marginTop: '2px', marginBottom: '2px', height: '3px'}} />
                          stop_id: <strong>
                          {(stop && stop.stop_id !== undefined && stop.stop_id != null)
                            ?stop.stop_id
                            :'n/a'
                          }
                          </strong>
                          <br/>
                          stop_code: <strong>
                          {(stop && stop.stop_code !== undefined && stop.stop_code != null)
                            ?stop.stop_code
                            :'n/a'
                          }
                          </strong>
                          <br/>
                          stop_desc: <strong>
                          {(stop && stop.stop_desc !== undefined && stop.stop_desc != null)
                            ?stop.stop_desc
                            :'n/a'
                          }
                          </strong>
                          <br/>
                          zone_id: <strong>
                          {(stop && stop.zone_id !== undefined && stop.zone_id != null)
                            ?stop.zone_id
                            :'n/a'
                          }
                          </strong>
                          <br/>
                          stop_url: <strong>
                          {(stop && stop.stop_url !== undefined && stop.stop_url != null)
                            ?stop.stop_url
                            :'n/a'
                          }
                          </strong>
                          <br/>
                          location_type: <strong>
                          {(stop && stop.location_type !== undefined && stop.location_type != null)
                            ?stop.location_type
                            :'n/a'
                          }
                          </strong>
                          <br/>
                          parent_station: <strong>
                          {(stop && stop.parent_station !== undefined && stop.parent_station != null)
                            ?stop.parent_station
                            :'n/a'
                          }
                          </strong>
                          <br/>
                          stop_timezone: <strong>
                          {(stop && stop.stop_timezone !== undefined && stop.stop_timezone != null)
                            ?stop.stop_timezone
                            :'n/a'
                          }
                          </strong>
                          <br/>
                          wheelchair_boarding: <strong>
                          {(stop && stop.wheelchair_boarding !== undefined && stop.wheelchair_boarding != null)
                            ?stop.wheelchair_boarding
                            :'n/a'
                          }
                          </strong>
                        </div>
                      </Tooltip>
                    }
                  >
                    <th 
                      style={{padding: '2px'}}
                      className={'text-left text-nowrap'+
                      (line_index%2==1?' sticky-th-left-white':' sticky-th-left')}
                    >
                      {line.stop_name}
                    </th>
                  </OverlayTrigger>
                  <th
                    style={{padding: '2px'}}
                    className='text-center'
                  >{line.lineNumber}</th>
                  {line.cells.map((cell, index) => {
                    return (
                      <OverlayTrigger 
                        id={'timetable-cell-'+line.lineNumber+'-'+index}
                        placement="left"                        
                        delayShow={400}
                        key={index}
                        overlay={
                            <Tooltip
                              id={'timetable-cell-tooltip-'+line.lineNumber+'-'+index}
                            >
                              {cell.empty
                                ?'stop not in pattern'
                                :(
                                  <div className='text-left'>                                    
                                    <center>stop_times.txt</center>
                                    <hr style={{marginTop: '2px', marginBottom: '2px', height: '3px'}} />
                                    arrival_time: <strong>
                                    {(cell.arrival_time !== undefined && cell.arrival_time != null)
                                      ?cell.arrival_time.format('HH:mm:ss')
                                      :'n/a'
                                    }
                                    </strong>
                                    <br/>
                                    departure_time: <strong>
                                    {(cell.departure_time !== undefined && cell.departure_time != null)
                                      ?cell.departure_time.format('HH:mm:ss')
                                      :'n/a'
                                    }
                                    </strong>
                                    <br/>
                                    stop_headsign: <strong>
                                    {(cell.stop_times_item.stop_headsign !== undefined && cell.stop_times_item.stop_headsign != null)
                                      ?cell.stop_times_item.stop_headsign
                                      :'n/a'
                                    }
                                    </strong>
                                    <br/>
                                    pickup_type: <strong>
                                    {(cell.stop_times_item.pickup_type !== undefined && cell.stop_times_item.pickup_type != null)
                                      ?cell.stop_times_item.pickup_type
                                      :'n/a'
                                    }
                                    </strong>
                                    <br/>
                                    drop_off_type: <strong>
                                    {(cell.stop_times_item.drop_off_type !== undefined && cell.stop_times_item.drop_off_type != null)
                                      ?cell.stop_times_item.drop_off_type
                                      :'n/a'
                                    }
                                    </strong>
                                    <br/>
                                    shape_dist_traveled: <strong>
                                    {(cell.stop_times_item.shape_dist_traveled !== undefined && cell.stop_times_item.shape_dist_traveled != null)
                                      ?cell.stop_times_item.shape_dist_traveled
                                      :'n/a'
                                    }
                                    </strong>
                                    <br/>
                                    timepoint: <strong>
                                    {(cell.stop_times_item.timepoint !== undefined && cell.stop_times_item.timepoint != null)
                                      ?cell.stop_times_item.timepoint
                                      :'n/a'
                                    }
                                    </strong>
                                  </div>
                                )
                          }
                            </Tooltip>
                        }
                      >
                        <td
                          /* className={`
                            ${(!cell.empty && !cell.time) ? 'small' : ''}
                            ${(cell.isTimepoint && activeFilter === 1) ? ' timetable-underline' : ''}
                          `} */
                          className={`
                            ${(!cell.empty && !cell.time) ? 'small' : ''}
                          `}
                          style={{ padding: '2px' }}
                        >
                          {cell.empty
                            ? '-'
                            : cell.time
                              ? cell.time.format('HH:mm')
                              : 'X'
                          }
                        </td>

                      </OverlayTrigger>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </Table>
        </Body>
        <Footer>
          <Nav
            className='small pull-left'
            bsStyle='pills'
            activeKey={activeFilter}>
            <NavItem
              eventKey={0}
              key={0}
              onClick={e => this._onSelectFilter(e, 0)}>
              All stops
            </NavItem>
            <NavItem
              eventKey={1}
              key={1}
              onClick={e => this._onSelectFilter(e, 1)}>
              Timepoints
            </NavItem>
            <NavItem
              eventKey={2}
              key={2}
              onClick={e => this._onSelectFilter(e, 2)}>
              Terminus
            </NavItem>
          </Nav>
        <Button onClick={this._close}>Close</Button>
        </Footer>
      </Modal>
    )
  }
}
