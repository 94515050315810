// @flow

import { createAction, type ActionType } from 'redux-actions'

import { createVoidPayloadAction, fetchGraphQL, secureFetch } from '../../common/actions'
import { getGraphQLFieldsForEntity } from '../../gtfs/util'
import { setErrorMessage } from '../../manager/actions/status'
import { entityIsNew } from '../util/objects'
import { EXCEPTION_EXEMPLARS } from '../util'
import { getEditorNamespace } from '../util/gtfs'

import type { Calendar, ScheduleException } from '../../types'
import type { dispatchFn, getStateFn, CalendarEditorState } from '../../types/reducers'

export const receiveServiceCalendar = createAction(
  'RECEIVE_SERVICE_CALENDAR',
  (payload: {
    calendar: Calendar,
    exceptionsAdded: Array<ScheduleException>,
    exceptionsRemoved: Array<ScheduleException>
  }) => payload
)
const requestingServiceCalendar = createVoidPayloadAction('REQUESTING_SERVICE_CALENDAR')
const requestingScheduleexceptionsToAdd = createVoidPayloadAction('REQUESTING_SCHEDULE_EXCEPTIONS_TO_ADD')
const savingServiceCalendar = createVoidPayloadAction('SAVING_SERVICE_CALENDAR')
const savedServiceCalendar = createVoidPayloadAction('SAVED_SERVICE_CALENDAR')

export type EditorCalendarModalActions = ActionType<typeof receiveServiceCalendar> |
  ActionType<typeof requestingServiceCalendar>

// REST actions
export function fetchServiceCalendar(
  feedId: string,
  calendar: CalendarEditorState,
  entityId: number,
  serviceId: string
) {
  return function (dispatch: dispatchFn, getState: getStateFn) {
    const namespace = getEditorNamespace(feedId, getState())
    const fieldsCalendar = getGraphQLFieldsForEntity('calendar', true)
    const fieldsScheduleExceptions = getGraphQLFieldsForEntity('scheduleexception', true)
    const query = `query ($namespace: String, $id: Int, $service_id: String) {
        feed(namespace: $namespace) {
          calendar (id: $id) {
            ${fieldsCalendar}
          }
          added: schedule_exceptions (added_service_id: $service_id) {
            id
            ${fieldsScheduleExceptions}
          }
          removed: schedule_exceptions (removed_service_id: $service_id) {
            id
            ${fieldsScheduleExceptions}
          }
        }
    }`
    dispatch(requestingServiceCalendar())
    // FIXME: string casting pattern id
    return dispatch(fetchGraphQL({
      query,
      variables: { namespace, id: entityId, service_id: serviceId },
      errorMessage: 'Could not fetch service calendar data'
    }))
      .then(data => dispatch(receiveServiceCalendar({
        calendar: data.feed.calendar[0],
        exceptionsAdded: data.feed.added,
        exceptionsRemoved: data.feed.removed
      })))
  }
}

/* export function fetchServiceCalendar(
  feedId: string,
  calendar: CalendarEditorState,
  entityId: number,
  serviceId: string
) {
  return function (dispatch: dispatchFn, getState: getStateFn) {
    const namespace = getEditorNamespace(feedId, getState())
    const fieldsCalendar = getGraphQLFieldsForEntity('calendar', true)
    const fieldsScheduleExceptions = getGraphQLFieldsForEntity('scheduleexception', true)
    const query = `query ($namespace: String, $id: Int, $service_id: String) {
        feed(namespace: $namespace) {
          calendar (id: $id) {
            ${fieldsCalendar}
          }
          added: schedule_exceptions (added_service_id: $service_id) {
            id
            ${fieldsScheduleExceptions}
          }
          removed: schedule_exceptions (removed_service_id: $service_id) {
            id
            ${fieldsScheduleExceptions}
          }
        }
    }`
    dispatch(requestingServiceCalendar());

    // Despacha a ação fetchGraphQL e retorna a Promessa
    return dispatch(fetchGraphQL({
      query,
      variables: { namespace, id: entityId, service_id: serviceId },
      errorMessage: 'Could not fetch service calendar data'
    }))
    .then(data => {
      if (data) {
        return dispatch(receiveServiceCalendar({
          calendar: data.feed.calendar[0],
          exceptionsAdded: data.feed.added,
          exceptionsRemoved: data.feed.removed
        }));
      } else {
        throw new Error('No data received');
      }
    })
    .catch(error => {
      console.error('Error fetching service calendar:', error);
    });
  }
} */
  

export function saveServiceCalendarSelection(
  feedId: string,
  calendar: CalendarEditorState,
  entityId: number,
  serviceId: string,
  daysAddInclude: Array<string>,
  daysAddExclude: Array<string>,
  daysRemoveInclude: Array<string>,
  daysRemoveExclude: Array<string>
) {
  return function (dispatch: dispatchFn, getState: getStateFn) {
    const errorDays = [];
    const sessionId = getState().editor.data.lock.sessionId || '';
    const exceptions = [];
    let toUpdate = [], toInsert = [], savingCount;

    const namespace = getEditorNamespace(feedId, getState())
    const fieldsScheduleExceptions = getGraphQLFieldsForEntity('scheduleexception', true)
    const query = daysAddInclude.length && daysAddExclude.length
      ? `query ($namespace: String, $daysAddInclude: [String], $daysAddExclude: [String]) {
          feed(namespace: $namespace) {
            daysAddInclude: schedule_exceptions (name: $daysAddInclude) {
              id
              ${fieldsScheduleExceptions}
            }
            daysAddExclude: schedule_exceptions (name: $daysAddExclude) {
              id
              ${fieldsScheduleExceptions}
            }
          }
        }`
      : daysAddInclude.length
        ? `query ($namespace: String, $daysAddInclude: [String]) {
              feed(namespace: $namespace) {
                daysAddInclude: schedule_exceptions (name: $daysAddInclude) {
                  id
                  ${fieldsScheduleExceptions}
                }
              }
            }`
        : daysAddExclude.length
          ? `query ($namespace: String, $daysAddExclude: [String]) {
              feed(namespace: $namespace) {
                daysAddExclude: schedule_exceptions (name: $daysAddExclude) {
                  id
                  ${fieldsScheduleExceptions}
                }
              }
            }`
          : `query ($namespace: String) {
              feed(namespace: $namespace) {
                namespace
              }
            }`
    dispatch(requestingScheduleexceptionsToAdd())
    // FIXME: string casting pattern id
    return dispatch(fetchGraphQL({
      query,
      variables: { namespace, daysAddInclude: daysAddInclude, daysAddExclude: daysAddExclude },
      errorMessage: 'Could not fetch service calendar data for create or update selected days'
    }))
      .then((data) => {
        daysAddInclude.map(day => {
          const beforeLen = toUpdate.length;
          if (data.feed.daysAddInclude && data.feed.daysAddInclude.length > 0) {
            data.feed.daysAddInclude.map((scheduleException) => {
              if (scheduleException.name == day) {
                scheduleException.added_service.push(serviceId);
                toUpdate.push(scheduleException);
              }
            });
          }
          if (beforeLen == toUpdate.length) {
            // not found
            toInsert.push({
              name: day,
              dates: [day],
              exemplar: EXCEPTION_EXEMPLARS.SWAP,
              custom_schedule: [],
              added_service: [serviceId],
              removed_service: []
            });
          }
        });
        daysAddExclude.map(day => {
          const beforeLen = toUpdate.length;
          if (data.feed.daysAddExclude && data.feed.daysAddExclude.length > 0) {
            data.feed.daysAddExclude.map((scheduleException) => {
              if (scheduleException.name == day) {
                scheduleException.removed_service.push(serviceId);
                toUpdate.push(scheduleException);
              }
            });
          }
          if (beforeLen == toUpdate.length) {
            // not found
            toInsert.push({
              name: day,
              dates: [day],
              exemplar: EXCEPTION_EXEMPLARS.SWAP,
              custom_schedule: [],
              added_service: [],
              removed_service: [serviceId]
            });
          }
        });
        daysRemoveInclude.map(day => {
          if (calendar.exceptionsAdded && calendar.exceptionsAdded.length > 0) {
            calendar.exceptionsAdded.map((scheduleException) => {
              if (scheduleException.name == day) {
                scheduleException.added_service = scheduleException.added_service.filter(service_id => service_id != serviceId);
                toUpdate.push(scheduleException);
              }
            });
          }
        });
        daysRemoveExclude.map(day => {
          if (calendar.exceptionsRemoved && calendar.exceptionsRemoved.length > 0) {
            calendar.exceptionsRemoved.map((scheduleException) => {
              if (scheduleException.name == day) {
                scheduleException.removed_service = scheduleException.removed_service.filter(service_id => service_id != serviceId);
                toUpdate.push(scheduleException);
              }
            });
          }
        });
      })
      .then(() => {
        dispatch(savingServiceCalendar());
        savingCount = toUpdate.length + toInsert.length;
        Promise.all(toUpdate.map((scheduleException) => {
          const url = `/api/editor/secure/scheduleexception/${scheduleException.id}?feedId=${feedId}&sessionId=${sessionId}`
          return dispatch(secureFetch(url, 'put', scheduleException))
            .then(res => {
              if (--savingCount == 0) {
                dispatch(savedServiceCalendar())
                dispatch(fetchServiceCalendar(
                  feedId,
                  calendar,
                  entityId,
                  serviceId
                ));
                return errorDays;
              }
              return res.json()
            })
            .catch(err => {
              console.warn(err)
              errorDays.push(scheduleException.name)
              if (--savingCount == 0) {
                dispatch(savedServiceCalendar())
              }
            })
        }));
        Promise.all(toInsert.map((scheduleException) => {
          const url = `/api/editor/secure/scheduleexception?feedId=${feedId}&sessionId=${sessionId}`
          return dispatch(secureFetch(url, 'post', scheduleException))
            .then(res => {
              if (--savingCount == 0) {
                dispatch(savedServiceCalendar())
              }
              return res.json()
            })
            .catch(err => {
              console.warn(err)
              errorDays.push(scheduleException.name)
              if (--savingCount == 0) {
                dispatch(savedServiceCalendar())
                return errorDays;
              }
            })
        }));
      })
  }
}
