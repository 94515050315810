// @flow

import Icon from '@conveyal/woonerf/components/icon'
import React, {Component} from 'react'
import {Button, Glyphicon, ButtonGroup, DropdownButton, MenuItem} from 'react-bootstrap'

import ConfirmModal from '../../../common/components/ConfirmModal'
import {getComponentMessages, isModuleEnabled, getIntegrationConfig} from '../../../common/util/config'

import GenSchedulesModal from '../../../common/components/GenSchedulesModal'
import type {FeedVersionSummary, ShapefileExportType} from '../../../types'

import type {ListProps} from './FeedVersionViewer'

type ToolbarProps = ListProps & {
  size: string,
  versionSummary: FeedVersionSummary
}

export default class VersionButtonToolbar extends Component<ToolbarProps> {
  messages = getComponentMessages('VersionButtonToolbar')
  integrationConfig=getIntegrationConfig()

  _onClickDownload = () => {
    const { downloadFeedViaToken, isPublic, versionSummary } = this.props
    downloadFeedViaToken(versionSummary.id, isPublic)
  }

  _onClickLoadIntoEditor = (evt: SyntheticMouseEvent<HTMLInputElement>) => {
    const {loadFeedVersionForEditing, versionSummary} = this.props

    const {id: feedVersionId, feedSourceId} = versionSummary
    this.refs.confirm.open({
      title: this.messages('load'),
      body: this.messages('confirmLoad'),
      onConfirm: () =>
        loadFeedVersionForEditing({feedSourceId, feedVersionId})
    })
  }

  _onClickDeleteVersion = (evt: SyntheticMouseEvent<HTMLInputElement>) => {
    const {deleteFeedVersion, versionSummary} = this.props
    this.refs.confirm.open({
      title: `${this.messages('delete')} ${this.messages('version')}`,
      body: this.messages('confirmDelete'),
      onConfirm: () => deleteFeedVersion(versionSummary)
    })
  }

  _onDownload = (type: ShapefileExportType) => {
    const {displayRoutesShapefile, feedSource, project} = this.props
    //console.log("FEED SOURCE: ", feedSource)
    if (type==="SCHEDULE" || 
      type==="SCHEDULES_ADVANCED" ||
      type==="BUS_STOP_SIGNS" || 
      type.substring(0,4)==="TSI_" || 
      type.substring(0,5)==="TSI2_") {
    let key=null
    if (type==="SCHEDULE") {
      key='1'
    } else if (type==="SCHEDULES_ADVANCED") {
      key='4'
    } else if (type==="BUS_STOP_SIGNS") {
      key = '5'
    } else if (type.substring(0,4)==="TSI_") {
      key='2'
    } else {
      key='3'
    }
    const {displayRoutesShapefile, feedSource, project} = this.props
    const tsiCodes=this.integrationConfig.TicketingSystemIntegration.CGI_Download
    const tsiCodes2=this.integrationConfig.TicketingSystemIntegration.CGI_Download2
    const tsiCode=feedSource.ticketing_system_integration_code
    var schedulesAdvanced_CGI=this.integrationConfig.SchedulesAdvancedCustomization._default;
    var BusStopSigns_CGI=this.integrationConfig.BusStopSigns.Ave;
    if (this.integrationConfig.SchedulesAdvancedCustomization[project.name]!==undefined) {
      if (this.integrationConfig.SchedulesAdvancedCustomization[project.name][feedSource.name]!==undefined) {
        schedulesAdvanced_CGI=this.integrationConfig.SchedulesAdvancedCustomization[project.name][feedSource.name];
      }
    }
    let title='???...'
    let notes='???...'
    switch (key) {
      case '1':
        title='Export Excel Schedules'
        notes='Excel file with one timetable worksheet per route (only timepoints)'
        break
      case '2':
        title='Export file (ticketing system integration)'
        notes=tsiCodes[tsiCode].description
        break
      case '3':
        title='Export file (ticketing system integration)'
        notes=tsiCodes2[tsiCode].description
        break
      case '4':
        title='Export Stop Schedule PDF\'s'
        notes='ZIP file containing one schedule PDF per stop and per page'
        break
        case '5':
          title='Export Stop Signs (Placas)'
          notes='ZIP file containing one or more PDF per stop'
          break
    }
    this.refs.genSchedulesModal.open({
      editor: false,
      compare: false,
      download_key: key,
      title: title,
      notes: notes,
      runJob: (job_id, key, compareVersionId) => {
        switch (key) {
          case '1':
            this.props.exportSchedules(
              this.props.version.id, 
              'version', 
              job_id,
              compareVersionId
            )            
            break
          case '2':
            this.props.exportTicketingSystemIntegration(
              this.props.version.id, 
              'version', 
              this.props.version.feedSource.ticketing_system_integration_code, 
              job_id
            )
            break
          case '3':
            this.props.exportTicketingSystemIntegration2(
              this.props.version.id, 
              'version', 
              this.props.version.feedSource.ticketing_system_integration_code, 
              job_id
            )
            break
          case '4':
            this.props.exportSchedulesAdvanced(
              this.props.version.id, 
              'version', 
              schedulesAdvanced_CGI,
              job_id,
              compareVersionId
            )            
            break
          case '5':
            this.props.exportBusStopSigns(
              this.props.version.id, 
              'feed', 
              BusStopSigns_CGI,
              job_id
            )            
            break
          }
        return true;
      }
    })
  } else {
    this.props.exportVersionShapes(this.props.version.id, type)
  } 
  }

  render () {
    const {
      deleteDisabled,
      deleteFeedVersion,
      editDisabled,
      hasVersions,
      isPublic,
      size,
      version,
      user
    } = this.props
    const {
      feedSource
    } = version
    const tsiCodes=this.integrationConfig.TicketingSystemIntegration.CGI_Download
    const tsiCodes2=this.integrationConfig.TicketingSystemIntegration.CGI_Download2
    const tsiCode=feedSource.ticketing_system_integration_code
    const isAppAdmin=(user && user.permissions && user.permissions.isApplicationAdmin())
    return (
      <div style={{display: 'inline'}}>
        <ConfirmModal ref='confirm' />
        <GenSchedulesModal ref='genSchedulesModal' {...this.props} />
        <ButtonGroup className='pull-right'>

          {/* "Download Feed" Button */}
          <Button
            bsSize={size}
            data-test-id='download-feed-version-button'
            disabled={!hasVersions}
            onClick={this._onClickDownload}>
            <Glyphicon glyph='download' />
            <span className='hidden-xs'> {this.messages('download')}</span>
            <span className='hidden-xs hidden-sm'> {this.messages('version')}</span>
          </Button>
          <DropdownButton
            bsSize={size}
            id='shp-export'
            title={
              <span>
                <Icon type='download' />
                <span className='hidden-xs'> Export</span>
              </span>
            }
            onSelect={this._onDownload}>
            <MenuItem eventKey='STOPS'><Icon type='map-marker' /> Stops (.shp)</MenuItem>
            <MenuItem eventKey='ROUTES'><Icon type='ellipsis-h' /> Routes (.shp)</MenuItem>
            <MenuItem eventKey='SCHEDULE'><Icon type='file-excel-o' /> Schedules (.xlsx)</MenuItem>
            <MenuItem eventKey='SCHEDULES_ADVANCED'><Icon type='file-zip-o' /> Stop Schedule PDF's (.zip)</MenuItem>
            {tsiCodes[tsiCode]
              ?
                <MenuItem 
                  eventKey={'TSI_'+tsiCode}
                ><Icon type={tsiCodes[tsiCode].icon} /> {tsiCodes[tsiCode].description}</MenuItem>
              :null
            }
            {tsiCodes2[tsiCode]
              ?
                <MenuItem 
                  eventKey={'TSI2_'+tsiCode}
                ><Icon type={tsiCodes2[tsiCode].icon} /> {tsiCodes2[tsiCode].description}</MenuItem>
              :null
            }
            {feedSource.id === 'ab07b9d0-e9b3-4e8d-b1f1-3baf015d6d43' ? <MenuItem eventKey='BUS_STOP_SIGNS'><Icon type='file-zip-o' /> Bus Stop Signs</MenuItem> : null}
            
          </DropdownButton>
          {/* "Load for Editing" Button */}
          {isModuleEnabled('editor') && !isPublic
            ? <Button
              bsSize={size}
              disabled={editDisabled || !hasVersions}
              onClick={this._onClickLoadIntoEditor}>
              <Glyphicon glyph='pencil' />
              <span className='hidden-xs'> {this.messages('load')}</span>
              <span className='hidden-xs hidden-sm'> {this.messages('version')}</span>
            </Button>
            : null
          }

          {/* "Delete Version" Button */}
          {!isPublic
            ? <Button
              bsSize={size}
              data-test-id='delete-feed-version-button'
              disabled={deleteDisabled || !hasVersions || typeof deleteFeedVersion === 'undefined'}
              onClick={this._onClickDeleteVersion}>
              <span className='text-danger'>
                <Icon type='trash' />
                <span className='hidden-xs'> {this.messages('delete')}</span>
                <span className='hidden-xs hidden-sm'> {this.messages('version')}</span>
              </span>
            </Button>
            : null
          }
        </ButtonGroup>
      </div>
    )
  }
}
