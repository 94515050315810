// @flow

import update from 'react-addons-update'
import clone from 'lodash/cloneDeep'

// import { sortAndFilterTrips } from '../util'

import type { Action } from '../../types/actions'
import type { TimetableModalState } from '../../types/reducers'

export const defaultState = {
  status: {
    fetched: false,
    error: false,
    fetching: false
  }
}

/* eslint-disable complexity */
const timetableModalState = (state: TimetableModalState = defaultState, action: Action): TimetableModalState => {
  switch (action.type) {
    case 'RECEIVE_TIMETABLE':
      const {
        id,
        route_id,
        route_short_name,
        route_long_name,
        route_desc,
        agency_id,
        route_type,
        route_url,
        route_color,
        route_text_color,
        route_sort_order,
        trips,
        tripPatterns,
        stop_times_ordered,
        stops,
        calendar
      } = action.payload
      return update(state, {
        route: { $set: {
          id,
          route_id,
          route_short_name,
          route_long_name,
          route_desc,
          agency_id,
          route_type,
          route_url,
          route_color,
          route_text_color,
          route_sort_order,
            trips,
          tripPatterns
        }},
        stop_times_ordered: {$set: stop_times_ordered },
        stops: {$set: stops },
        calendar: {$set: calendar },
        status: {
          fetched: { $set: true },
          error: { $set: false },
          fetching: { $set: false }
        }
      })
    case 'REQUESTING_TIMETABLE':
      return update(state, {
        status: {
          fetched: { $set: false },
          error: { $set: false },
          fetching: { $set: true }
        }
      })
    default:
      return state
  }
}

export default timetableModalState
