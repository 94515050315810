// @flow

import Icon from '@conveyal/woonerf/components/icon'
import React, {Component} from 'react'
import {Badge, Button, ButtonGroup, Tooltip, OverlayTrigger, Nav, NavItem} from 'react-bootstrap'

import {getComponentMessages} from '../../common/util/config'
import * as activeActions from '../actions/active'
import * as mapActions from '../actions/map'
import {getEntityBounds, getEntityName} from '../util/gtfs'
import {entityIsNew} from '../util/objects'
import {GTFS_ICONS} from '../util/ui'
import type {Entity, Feed, GtfsRoute, GtfsStop, Pattern} from '../../types'
import type {MapState} from '../../types/reducers'
import type {EditorValidationIssue} from '../util/validation'

import ActiveTimetableAdvancedModal from '../containers/ActiveTimetableAdvancedModal'
import ActiveBusStopSignModal from '../containers/ActiveBusStopSignModal'

type RouteWithPatterns = {tripPatterns: Array<Pattern>} & GtfsRoute

type Props = {
  activeComponent: string,
  activeEntity: Entity,
  activePattern: Pattern,
  activePatternStops: Array<GtfsStop>,
  editFareRules: boolean,
  entityEdited: boolean,
  feedSource: Feed,
  mapState: MapState,
  resetActiveGtfsEntity: typeof activeActions.resetActiveGtfsEntity,
  saveActiveGtfsEntity: typeof activeActions.saveActiveGtfsEntity,
  setActiveEntity: typeof activeActions.setActiveEntity,
  subComponent: string,
  subEntity: number,
  toggleEditFareRules: boolean => void,
  updateMapSetting: typeof mapActions.updateMapSetting,
  validationErrors: Array<EditorValidationIssue>
}

type State = {
  showTimetableAdvancedModal: boolean,
  showBusStopSignModal: boolean
}

export default class EntityDetailsHeader extends Component<Props, State> {

  state = {
    showTimetableAdvancedModal: false,
    showBusStopSignModal: false
  }
  
  messages = getComponentMessages('EntityDetailsHeader')

  _onClickSave = () => {
    if (this.props.subComponent === 'trippattern') {
      this.props.saveActiveGtfsEntity('trippattern')
    } else {
      this.props.saveActiveGtfsEntity(this.props.activeComponent)
    }
  }

  _onClickUndo = () => {
    const {
      activeComponent,
      activeEntity,
      activePattern,
      resetActiveGtfsEntity,
      subComponent
    } = this.props
    if (subComponent === 'trippattern') {
      const castedRoute = ((activeEntity: any): RouteWithPatterns)
      const pattern = castedRoute.tripPatterns.find(p => p.id === activePattern.id)
      if (pattern) resetActiveGtfsEntity({ entity: pattern, component: 'trippattern' })
      else console.warn(`Could not locate pattern with id=${activePattern.id}`)
    } else {
      resetActiveGtfsEntity({ entity: activeEntity, component: activeComponent })
    }
  }

  _onClickZoomTo = () => {
    const { activeEntity, activePatternStops, subEntity, updateMapSetting } = this.props
    let props
    if (subEntity) {
      const castedRoute = ((activeEntity: any): RouteWithPatterns)
      const pattern = castedRoute.tripPatterns.find(p => p.id === subEntity)
      props = { bounds: getEntityBounds(pattern, activePatternStops), target: subEntity }
    } else {
      props = { bounds: getEntityBounds(activeEntity), target: +activeEntity.id }
    }
    updateMapSetting(props)
  }

  _showFareAttributes = () => this.props.toggleEditFareRules(false)

  _showFareRules = () => this.props.toggleEditFareRules(true)

  _showRoute = () => {
    const {
      activeComponent,
      activeEntity,
      feedSource,
      setActiveEntity,
      subComponent
    } = this.props
    if (subComponent === 'trippattern') {
      setActiveEntity(feedSource.id, activeComponent, activeEntity)
    }
  }

  _showTripPatterns = () => {
    const {
      activeComponent,
      activeEntity,
      feedSource,
      setActiveEntity,
      subComponent
    } = this.props
    if (subComponent !== 'trippattern') {
      setActiveEntity(feedSource.id, activeComponent, activeEntity, 'trippattern')
    }
  }

  _hideTimetableAdvancedModal = () => this.setState({showTimetableAdvancedModal: false})
  _hideBusStopSignModal = () => this.setState({showBusStopSignModal: false})

  _showTimetableAdvancedModal = () => {
    document.activeElement.blur();
    this.setState({showTimetableAdvancedModal: true})
  }

  _showBusStopSignModal = () => {
    document.activeElement.blur();
    this.setState({showBusStopSignModal: true})
  }

  render () {
    const {
      activeComponent,
      activeEntity,
      editFareRules,
      entityEdited,
      mapState,
      subComponent,
      subEntity,
      validationErrors,
      feedSource
    } = this.props
    const validationTooltip = (
      <Tooltip id='tooltip'>
        {validationErrors.map((v, i) => (
          <p key={i}>{v.field}: {v.reason}</p>
        ))}
      </Tooltip>
    )
    const hasErrors = validationErrors.length > 0
    const entityName = activeComponent === 'feedinfo'
      ? this.messages('feedInfo')
      : getEntityName(activeEntity)
    const icon = GTFS_ICONS.find(i => i.id === activeComponent)
    const zoomDisabled = activeEntity && !subComponent
      ? mapState.target === activeEntity.id
      : mapState.target === subEntity
    const iconName = icon ? icon.icon : null
    const nameWidth = activeComponent === 'stop' || activeComponent === 'route'
      ? '176px'
      : '210px'
    return (
      <div className='entity-details-header'>
        <ActiveTimetableAdvancedModal
          feedSource={feedSource}
          onClose={this._hideTimetableAdvancedModal}
          show={this.state.showTimetableAdvancedModal}/>
        <ActiveBusStopSignModal
          feedSource={feedSource}
          onClose={this._hideBusStopSignModal}
          show={this.state.showBusStopSignModal}/>
        <h5 className='entity-details-heading'>
          {/* Zoom, undo, and save buttons */}
          <ButtonGroup className='pull-right'>
            {activeComponent === 'stop' || activeComponent === 'route'
              ? <OverlayTrigger
                rootClose
                placement='bottom'
                overlay={<Tooltip id='tooltip'>{this.messages('zoomTo')} {activeComponent}</Tooltip>}>
                <Button
                  bsSize='small'
                  disabled={zoomDisabled}
                  onClick={this._onClickZoomTo}>
                  <Icon type='search' />
                </Button>
              </OverlayTrigger>
              : null
            }
            <OverlayTrigger
              rootClose
              placement='bottom'
              overlay={<Tooltip id='tooltip'>{this.messages('undoChanges')}</Tooltip>}>
              <Button
                bsSize='small'
                disabled={!entityEdited}
                onClick={this._onClickUndo}>
                <Icon type='undo' />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              rootClose
              placement='bottom'
              overlay={<Tooltip id='tooltip'>{this.messages('saveChanges')}</Tooltip>}>
              <Button
                bsSize='small'
                data-test-id='save-entity-button'
                disabled={!entityEdited || hasErrors || activeComponent=='scheduleexception'}
                onClick={this._onClickSave}>
                <Icon type='floppy-o' />
              </Button>
            </OverlayTrigger>
          </ButtonGroup>
          <span className='entity-details-title' style={{width: nameWidth}}>
            {/* Entity Icon */}
            <span style={{position: 'relative', top: '-4px'}}>
              {activeComponent === 'route'
                ? <span className='fa-stack'>
                  <Icon
                    type='square'
                    style={{color: `#${activeEntity.route_color || 'fff'}`}}
                    className='fa-stack-2x' />
                  <Icon
                    type='bus'
                    style={{color: `#${activeEntity.route_text_color || '000'}`}}
                    className='fa-stack-1x' />
                </span>
                : iconName
                  ? <span className='fa-stack'>
                    <Icon type='square' className='fa-stack-2x' />
                    <Icon type={iconName} className='fa-inverse fa-stack-1x' />
                  </span>
                  // schedule exception icon if no icon found
                  : <span className='fa-stack'>
                    <Icon type='calendar' className='fa-stack-1x' />
                    <Icon type='ban' className='text-danger fa-stack-2x' />
                  </span>
              }
            </span>
            {'  '}
            {/* Entity name */}
            <span
              title={entityName}
              className='entity-details-name'>
              {entityName}
            </span>
          </span>
        </h5>
        {/* Validation issues */}
        <p style={{marginBottom: '2px'}}>
          <small style={{marginTop: '3px'}} className='pull-right'>
            <em className='text-muted'>{this.messages('requiredField')}</em>
          </small>
          <small
            className={`${hasErrors ? ' text-danger' : ' text-success'}`}>
            {hasErrors
              ? <span>
                <Icon type='times-circle' />
                {' '}
                Fix
                {' '}
                <OverlayTrigger
                  placement='bottom'
                  overlay={validationTooltip}>
                  <span style={{borderBottom: '1px dotted #000'}}>
                    {validationErrors.length} {this.messages('validationIssues')}
                  </span>
                </OverlayTrigger>
              </span>
              : <span><Icon type='check-circle' /> {this.messages('noValidationIssues')}</span>
            }
          </small>
        </p>
        <div className='clearfix' />
        {activeComponent === 'route'
          ? <Nav 
              //style={{marginBottom: '5px'}} bsStyle='tabs'
              style={{marginBottom: '5px'}} bsStyle='pills'
              justified>
            <NavItem
              eventKey={'route'}
              active={subComponent !== 'trippattern'}
              onClick={this._showRoute}>
              {this.messages('routeDetails')}
            </NavItem>
            <OverlayTrigger placement='bottom' overlay={<Tooltip id='tooltip'>Trip patterns define a route&rsquo;s unique stop sequences and timings.</Tooltip>}>
              <NavItem
                active={subComponent === 'trippattern'}
                data-test-id='trippattern-tab-button'
                disabled={!activeEntity || entityIsNew(activeEntity)}
                eventKey={'trippattern'}
                onClick={this._showTripPatterns}
                className='tripPattersBtn' 
              >
                <div className="row" style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '5px'}}>
                  <span className="col-6">{this.messages('tripPatterns')}</span>
                  <Badge 
                    className="col-6"
                    style={{
                      backgroundColor: subComponent === 'trippattern' ? '#fff' : '#636363',
                      color: subComponent === 'trippattern' ? '#000' : '#fff'
                    }}
                  >
                    {activeEntity.tripPatterns ? activeEntity.tripPatterns.length : 0}
                  </Badge>
                </div>                
              </NavItem>
            </OverlayTrigger>
          </Nav>
          : activeComponent === 'fare'
            ? <Nav style={{marginBottom: '5px'}} 
                //bsStyle='tabs'
                style={{marginBottom: '5px'}} bsStyle='pills'
                justified>
              <NavItem
                active={!editFareRules}
                data-test-id='fare-attributes-tab-button'
                eventKey={'fare'}
                onClick={this._showFareAttributes}
              >
                {this.messages('attributes')}
              </NavItem>
              <NavItem
                active={editFareRules}
                data-test-id='fare-rules-tab-button'
                disabled={!activeEntity || entityIsNew(activeEntity)}
                eventKey={'rules'}
                onClick={this._showFareRules}
              >
                {this.messages('rules')}
              </NavItem>
            </Nav>
            : activeComponent === 'stop'
            ? <div style={{display: 'flex', flexDirection: 'column', gap: '10px', margin: '5px 0px'}}>
                <div>
                  <Button
                    bsSize='small'
                    style={{marginTop: '-5px', marginLeft: '10px'}}
                    onClick={this._showTimetableAdvancedModal}>
                    <Icon type='calendar' />
                  </Button>
                  <small>
                    <strong 
                      style={{marginLeft: '10px'}}
                    >Static Passenger Information</strong>     
                  </small>
                </div>
                {feedSource.id === 'ab07b9d0-e9b3-4e8d-b1f1-3baf015d6d43' ? 
                <div>
                <Button
                  bsSize='small'
                  style={{marginTop: '-5px', marginLeft: '10px'}}
                  onClick={this._showBusStopSignModal}>
                  <Icon type='stop' />
                </Button>
                <small>
                  <strong 
                    style={{marginLeft: '10px'}}
                  >Bus Stop Sign</strong>     
                </small>
              </div>
                : null}
                
              </div>
            : null
        }
      </div>
    )
  }
}
