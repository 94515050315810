// @flow

import update from 'react-addons-update'
import {latLngBounds} from 'leaflet'
import type {ActionType} from 'redux-actions'

import {receivedRoutesShapefile, updateMapSetting} from '../actions/map'
import {receiveFeedSource} from '../../manager/actions/feeds'
import { getFeedBounds } from '../util/map'

import type {MapState} from '../../types/reducers'

export const defaultState = {
  zoom: null,
  bounds: latLngBounds([[60, 60], [-60, -20]]), // entire globe
  routesGeojson: null,
  regionPolygons: null,
  target: null,
  agencyZoningToFetch: [],
  agencyZonings: [],
  agencyZoneStops: [],
  agencyZoneStopsToFetch: []
}

export const reducers = {
  'RECEIVE_FEEDSOURCE' (
    state: MapState,
    action: ActionType<typeof receiveFeedSource>
  ): MapState {
    if (action.payload) {
      return update(state, {
        bounds: {$set: latLngBounds(getFeedBounds(action.payload))},
        target: {$set: action.payload && action.payload.id}
      })
    } else {
      return state
    }
  },
  'SET_AGENCY_ZONING_TO_FETCH' (
    state: MapState,
    action: ActionType<typeof setAgencyZoningToFetch>
  ) {
    var nextAgencyZoningToFetch = []
    state.agencyZoningToFetch.map(entityId=>{
      if (entityId !== action.payload.entityId) {
        nextAgencyZoningToFetch.push(entityId)
      }
    })    
    nextAgencyZoningToFetch.push(action.payload.entityId)
    return update(state, {
      agencyZoningToFetch: {$set: nextAgencyZoningToFetch}
    })
  },
  'RECEIVED_ZONING_SHAPEFILE' (
    state: MapState,
    action: ActionType<typeof receivedZoningShapeFile>
  ) {
    // agencyZonings
    var nextAgencyZonings = []
    state.agencyZonings.map(agencyZoning=>{
      if (agencyZoning.entityId !== action.payload.entityId) {
        nextAgencyZonings.push(agencyZoning)
      }
    })    
    nextAgencyZonings.push({
      entityId: action.payload.entityId, 
      shape: action.payload.zoningPolygons
    })
    // agencyZoningToFetch
    var nextAgencyZoningToFetch = []
    state.agencyZoningToFetch.map(entityId=>{
      if (entityId !== action.payload.entityId) {
        nextAgencyZoningToFetch.push(entityId)
      }
    })    
    return update(state, {
      agencyZonings: {$set: nextAgencyZonings},
      agencyZoningToFetch: {$set: nextAgencyZoningToFetch}
    })
  },
  'SET_AGENCY_ZONESTOPS_TO_FETCH' (
    state: MapState,
    action: ActionType<typeof setAgencyZoneStopsToFetch>
  ) {
    var nextAgencyZoneStopsToFetch = []
    state.agencyZoneStopsToFetch.map(entityId=>{
      if (entityId !== action.payload.entityId) {
        nextAgencyZoneStopsToFetch.push(entityId)
      }
    })    
    nextAgencyZoneStopsToFetch.push(action.payload.entityId)
    return update(state, {
      agencyZoneStopsToFetch: {$set: nextAgencyZoneStopsToFetch}
    })
  },
  'RECEIVED_ZONESTOPS_SHAPEFILE' (
    state: MapState,
    action: ActionType<typeof receivedZoneStopsShapeFile>
  ) {
    // agencyZonings
    var nextAgencyZoneStops = []
    state.agencyZoneStops.map(agencyZoneStops=>{
      if (agencyZoneStops.entityId !== action.payload.entityId) {
        nextAgencyZoneStops.push(agencyZoneStops)
      }
    })    
    nextAgencyZoneStops.push({
      entityId: action.payload.entityId, 
      shape: action.payload.zoneStopsPoints
    })
    // agencyZoningToFetch
    var nextAgencyZoneStopsToFetch = []
    state.agencyZoneStopsToFetch.map(entityId=>{
      if (entityId !== action.payload.entityId) {
        nextAgencyZoneStopsToFetch.push(entityId)
      }
    })    
    return update(state, {
      agencyZoneStops: {$set: nextAgencyZoneStops},
      agencyZoneStopsToFetch: {$set: nextAgencyZoneStopsToFetch}
    })
  },
  'RECEIVED_ROUTES_SHAPEFILE' (
    state: MapState,
    action: ActionType<typeof receivedRoutesShapefile>
  ) {
    return update(state, {
      routesGeojson: {$set: action.payload.geojson},
      regionPolygons: {$set: action.payload.regionPolygons}
    })
  },
  'UPDATE_MAP_SETTING' (
    state: MapState,
    action: ActionType<typeof updateMapSetting>
  ) {
    const updatedState = {}
    for (const key in action.payload) {
      if (key === 'bounds' && !action.payload[key]) {
        // do nothing. Setting bounds to null would cause an error for Leaflet
      } else {
        updatedState[key] = {$set: action.payload[key]}
      }
    }
    if (!('target' in action.payload)) {
      // If no target present in payload, set to null (no target to focus on)
      updatedState.target = {$set: null}
    }
    return update(state, updatedState)
  }
}
