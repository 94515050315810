// @flow

import React, { Component } from 'react'
import { 
  Modal, 
  Button
} from 'react-bootstrap'

import * as tripActions from '../actions/trip'

import {getIntegrationConfig} from '../../common/util/config'
import type { busStopSignModalState } from '../../types/reducers'

type Props = {
  stop_id: string,
  feedSource: Feed,
  project: Project,
  busStopSignModalState: BusStopSignState,
  busStopSignModalStatus: FetchStatus,
  fetchBusStopSign: typeof tripActions.fetchBusStopSign,
  resetStatusBusStopSign: typeof tripActions.resetStatusBusStopSign,
  onClose: () => void,
  show: boolean
}

type State = {
  showModal: boolean
}

export default class BusStopSignModal extends Component<Props, State> {
  integrationConfig=getIntegrationConfig()
  state = {
    showModal: this.props.show,
  }

  /**
   * Handle re-fetching calendar if stated to refresh.
   */
  componentWillReceiveProps(nextProps: Props) {
    const { 
      show: previousShow,
      busStopSignModalState: previousbusStopSignModalState
    } = this.props;
    const {
      stop_id,
      busStopSignModalState,
      feedSource,
      project,
      show
    } = nextProps;
    const showChanged = previousShow !== show;
    if (feedSource && stop_id && showChanged && show){
      // Only fetch trips if the feed, pattern, and schedule are present.
      var cgi_name=this.integrationConfig.BusStopSigns.Ave;
      /* if (this.integrationConfig.SchedulesAdvancedCustomization[project.name]!==undefined) {
        if (this.integrationConfig.SchedulesAdvancedCustomization[project.name][feedSource.name]!==undefined) {
          cgi_name=this.integrationConfig.SchedulesAdvancedCustomization[project.name][feedSource.name];
        }
      } */
      this.props.fetchBusStopSign(feedSource.id, busStopSignModalState, stop_id, cgi_name);
    }
  }

  _close = () => {
    const { onClose } = this.props
    this.props.resetStatusBusStopSign()
    onClose && onClose()
  }

  _download = () => {
    const {
      feedSource,
      busStopSignModalState
    } = this.props;
    const {
      url,
      type,
      filename
    } = busStopSignModalState
    var anchor = document.createElement('a');
    anchor.setAttribute('href', url);
    anchor.setAttribute('download', filename);
    anchor.click();
}

  render() {
    const {
      show,
      feedSource,
      busStopSignModalState,
      busStopSignModalStatus
    } = this.props;
    const {
      url,
      type,
      filename
    } = busStopSignModalState
    const { Body, Footer, Header, Title } = Modal
    return (
      <Modal
        bsSize="large"
        dialogClassName='timetable-modal'
        show={this.props.show}
        onHide={this._close}>
        <Header>
        </Header>
        <Body
          style={{
            fontSize: 'small', 
            padding: '0px',
            margin: '0px',
            background: 'white',            
            textAlign: 'center'
          }}
        >
        {!busStopSignModalStatus.fetching && show
        ?<object style={{width: "100%", height: "80vh", border: "0"}} data={url} type={type} name={filename}/>
        :<object style={{width: "100%", height: "80vh", border: "0"}}/>
        }
        </Body>
        <Footer>
        <Button
          onClick={this._download}
          disabled={type!=='application/pdf'}
        >Download</Button>        
        <Button onClick={this._close}>Close</Button>
        </Footer>
      </Modal>
    )
  }
}
