// @flow

import React, { Component } from 'react'
import { Modal, Button, Table } from 'react-bootstrap'
import moment, { months } from 'moment'

import * as calendarActions from '../actions/calendar'

import type { Feed, ServiceCalendar } from '../../types'
import type { EditorTables, CalendarEditorState } from '../../types/reducers'

type Props = {
  activeEntityId: number,
  activeServiceId: string,
  activeServiceCalendar: ServiceCalendar,
  feedSource: Feed,
  tableData: EditorTables,
  calendarEditorState: CalendarEditorState,
  calendarEditorStatus: FetchStatus,
  onClose: () => void,
  fetchServiceCalendar: typeof calendarActions.fetchServiceCalendar,
  saveServiceCalendarSelection: typeof calendarActions.saveServiceCalendarSelection,
  show: boolean
}

type State = {
  showModal: boolean,
  selectionStart: string
}

export default class CalendarEditorModal extends Component<Props, State> {
  state = {
    showModal: this.props.show,
    selectionStart: null
  }

  /**
   * Handle re-fetching calendar if stated to refresh.
   */
  componentWillReceiveProps(nextProps: Props) {
    const { 
      show: previousShow,
      calendarEditorState: previousCalendarEditorState
    } = this.props;
    const {
      feedSource,
      fetchServiceCalendar,
      activeEntityId,
      activeServiceId,
      calendarEditorState,
      show
    } = nextProps;
    const showChanged = previousShow !== show;
    const savingChanged = calendarEditorState.status.saving !== previousCalendarEditorState.status.saving;
    if (feedSource && activeServiceId && 
      (
        (showChanged && show) || 
        (savingChanged && !calendarEditorState.status.saving)
      ) 
    ){
      // Only fetch trips if the feed, pattern, and schedule are present.
      this.props.fetchServiceCalendar(feedSource.id, calendarEditorState, activeEntityId, activeServiceId);
    }
  }

  _close = () => {
    /*
    this.setState({
      calendarEditorState.calendar: null
    })
    */
    const { onClose } = this.props
    onClose && onClose()
  }

  _prepareDayForSave = (
    day: string,
    daysAddInclude: Array<string>,
    daysAddExclude: Array<string>,
    daysRemoveInclude: Array<string>,
    daysRemoveExclude: Array<string>,
    calendarEditorState: CalendarEditorState,
    start_date: Object,
    end_date: Object,
    includeEmptyDays: boolean
  ) => {
    let index;
    // state = 2
    index = calendarEditorState.exceptionsRemoved.findIndex(e => e.name === day);
    if (index >= 0) {
      daysRemoveExclude.push(day)
      return;
    }
    // state = 1
    index = calendarEditorState.exceptionsAdded.findIndex(e => e.name === day);
    if (index >= 0) {
      daysRemoveInclude.push(day)
      return;
    }
    // state = 3
    const mDay = moment(day,'YYYYMMDD');
    if (
      start_date &&
      end_date &&
      mDay >= start_date &&
      mDay <= end_date &&
      calendarEditorState.calendar[mDay.format("dddd").toLowerCase()]) {
      daysAddExclude.push(day);
      return;
    }
    // state = 0
    if (includeEmptyDays) {
      daysAddInclude.push(day);
      return;
    }
  }

  _onDaySave = (event, cellDay) => {
    event.target.blur();
    const {
      shiftKey,
      ctrlKey
    } = event
    const {
      feedSource,
      saveServiceCalendarSelection,
      activeEntityId,
      activeServiceId,
      calendarEditorState
    } = this.props;
    const {
      selectionStart
    } = this.state;
    let
      daysAddInclude = [],
      daysAddExclude = [],
      daysRemoveInclude = [],
      daysRemoveExclude = [],
      index,
      start_date,
      end_date;
    if (calendarEditorState.calendar.start_date) {
      start_date = moment(calendarEditorState.calendar.start_date,'YYYYMMDD').toDate();
    }
    if (calendarEditorState.calendar.end_date) {
      end_date = moment(calendarEditorState.calendar.end_date,'YYYYMMDD').toDate();
    }
    if (!shiftKey) {
      this.setState({ selectionStart: null });
      this._prepareDayForSave(
        cellDay,
        daysAddInclude,
        daysAddExclude,
        daysRemoveInclude,
        daysRemoveExclude,
        calendarEditorState,
        start_date,
        end_date,
        true
      );
    }
    if (shiftKey && selectionStart) {
      // loop days
      const loop_day = moment(selectionStart,'YYYYMMDD');
      const end_day = moment(cellDay,'YYYYMMDD');
      this.setState({ selectionStart: null });
      while (loop_day <= end_day) {
        this._prepareDayForSave(
          loop_day.format("YYYYMMDD"),
          daysAddInclude,
          daysAddExclude,
          daysRemoveInclude,
          daysRemoveExclude,
          calendarEditorState,
          start_date,
          end_date,
          ctrlKey
        );
        loop_day.add(1, 'days');
      }
    } else if (shiftKey) {
      this.setState({ selectionStart: cellDay });
    }
    if (
      daysAddInclude.length ||
      daysAddExclude.length ||
      daysRemoveInclude.length ||
      daysRemoveExclude.length
    ) {
      saveServiceCalendarSelection(
        feedSource.id,
        calendarEditorState,
        activeEntityId,
        activeServiceId,
        daysAddInclude,
        daysAddExclude,
        daysRemoveInclude,
        daysRemoveExclude
      );
    }
    return true;
  }

  render() {
    const {
      calendarEditorState: data
    } = this.props
    const { Body, Footer, Header, Title } = Modal
    const months = []
    const added_days = []
    const removed_days = []
    const exceptions_indexed = {}
    var min = null
    var max = null
    if (data && data.calendar) {
      for (var i = 0; i < data.exceptionsAdded.length; i++) {
        added_days.push(data.exceptionsAdded[i].dates)
        exceptions_indexed[data.exceptionsAdded[i].dates] = 1
      }
      for (var i = 0; i < data.exceptionsRemoved.length; i++) {
        removed_days.push(data.exceptionsRemoved[i].dates)
        exceptions_indexed[data.exceptionsRemoved[i].dates] = 2
      }
      const added_days_sorted = added_days.sort();
      const removed_days_sorted = added_days.sort();
      var start_date = null;
      if (data.calendar.start_date) {
        start_date = moment(data.calendar.start_date,'YYYYMMDD').toDate();
        min = start_date;
      }
      if (added_days_sorted.length > 0) {
        var added_days_sorted_min = moment(added_days_sorted[0],'YYYYMMDD').toDate();
        if (!min || added_days_sorted_min < min) {
          min = added_days_sorted_min;
        }
      }
      var end_date = null;
      if (data.calendar.end_date) {
        end_date = moment(data.calendar.end_date,'YYYYMMDD').toDate();
        max = end_date;
      }
      if (added_days_sorted.length > 0) {
        var added_days_sorted_max = moment(added_days_sorted[added_days_sorted.length - 1],'YYYYMMDD').toDate();
        if (!max || added_days_sorted_max > max) {
          max = added_days_sorted_max;
        }
      }
    }
    if (min && max) {
      if (min) {
        min = moment(min,'YYYYMMDD');
        min = min.set('date', 1);
        const min_month = min.get('month');
        min = min.set('month', min_month - (min_month % 3)).subtract(0, 'months');
      }
      if (max) {
        max = moment(max,'YYYYMMDD');
        max = max.set('date', 1);
        const max_month = max.get('month');
        max = max.set('month', max_month - (max_month % 3)).add(3, 'months').subtract(1, 'days');
      }
      var current_day = {
        day: min.clone(),
        status: 0
      };
      var month = -1;
      var week = -1;
      var weekDay = -1
      var current_month = null;
      var current_week = null;
      while (current_day.day <= max) {
        if (current_day.day.get('date') == 1) {
          if (current_week) {
            for (var i = current_week.weekdays.length; i < 7; i++) {
              current_week.weekdays.push({
                day: null,
                status: -1
              });
            }
            current_month.weeks.push({ ...current_week });
            if (current_month.weeks.length == 4) {
              current_month.weeks.unshift({
                weekdays: [
                  { day: null, status: -1 },
                  { day: null, status: -1 },
                  { day: null, status: -1 },
                  { day: null, status: -1 },
                  { day: null, status: -1 },
                  { day: null, status: -1 },
                  { day: null, status: -1 }
                ]
              });
            }
            if (current_month.weeks.length == 5) {
              current_month.weeks.push({
                weekdays: [
                  { day: null, status: -1 },
                  { day: null, status: -1 },
                  { day: null, status: -1 },
                  { day: null, status: -1 },
                  { day: null, status: -1 },
                  { day: null, status: -1 },
                  { day: null, status: -1 }
                ]
              });
            }
            current_week.weekdays = [];
          }
          current_month = null;
          current_month = {
            year: current_day.day.get('year'),
            month: current_day.day.get('month') + 1,
            month_name: current_day.day.format("MMMM"),
            weeks: []
          }
          months.push(current_month);
          current_week = {
            weekdays: []
          };
          for (var i = 1; i < current_day.day.isoWeekday(); i++) {
            current_week.weekdays.push({
              day: null,
              status: -1
            });
          }
        } else {
          if (current_day.day.isoWeekday() == 1) {
            current_month.weeks.push({ ...current_week });
            current_week.weekdays = [];
          }
        }
        if (
          current_day.day >= start_date &&
          current_day.day <= end_date &&
          data.calendar[current_day.day.format("dddd").toLowerCase()]) {
          current_day.status = 3;
        }
        if (
          current_day.day < start_date ||
          current_day.day > end_date) {
          current_day.status = -2;
        }
        if (exceptions_indexed.hasOwnProperty(current_day.day.format("YYYYMMDD"))) {
          current_day.status = exceptions_indexed[current_day.day.format("YYYYMMDD")];
        }
        current_week.weekdays.push({
          day: current_day.day.clone(),
          status: current_day.status
        })
        current_day.day.add(1, 'days');
        current_day.status = 0;
      }
      if (current_week) {
        for (var i = current_week.weekdays.length; i < 7; i++) {
          current_week.weekdays.push({
            day: null,
            status: -1
          });
        }
        current_month.weeks.push({ ...current_week });
        if (current_month.weeks.length == 4) {
          current_month.weeks.push({
            weekdays: [
              { day: null, status: -1 },
              { day: null, status: -1 },
              { day: null, status: -1 },
              { day: null, status: -1 },
              { day: null, status: -1 },
              { day: null, status: -1 },
              { day: null, status: -1 }
            ]
          });
        }
        if (current_month.weeks.length == 5) {
          current_month.weeks.push({
            weekdays: [
              { day: null, status: -1 },
              { day: null, status: -1 },
              { day: null, status: -1 },
              { day: null, status: -1 },
              { day: null, status: -1 },
              { day: null, status: -1 },
              { day: null, status: -1 }
            ]
          });
        }
      }
    }
    return (
      <Modal
        show={this.props.show}
        onHide={this._close}>
        <Header closeButton>
          <Title className='text-center'>Service Calendar Editor</Title>
        </Header>
        <Body>
          <div className='row'>
            {Object.keys(months).map(m_key => {
              return (
                <div className='col-xs-4' key={m_key}>
                  <Table className='small text-center' condensed style={{minHeight: '220px'}}>
                    <thead>
                      <tr>
                        <th colSpan='7'>
                          <div className="pull-left">
                            <h4>{months[m_key].month_name}</h4>
                          </div>
                          <div className="pull-right">
                            <h4 className="text-right">{months[m_key].year}</h4>
                          </div>
                          <div className="clearfix"></div>
                        </th>
                      </tr>
                      <tr>
                        <th>Mo</th>
                        <th>Tu</th>
                        <th>We</th>
                        <th>Th</th>
                        <th>Fr</th>
                        <th>Sa</th>
                        <th>Su</th>
                      </tr>
                    </thead>
                    <tbody>
                      {months[m_key].weeks.map((week, w_key) => {
                        return (
                          <tr key={w_key}>
                            {week.weekdays.map((weekday, d_key) => {
                              return (
                                <td
                                  style={{
                                    backgroundColor: weekday.status == 3 ? '#82B1FE' :
                                                      weekday.status == 1 ? '#59B542' :
                                                        weekday.status == 2 ? '#FD9388' :
                                                          weekday.status == -2 ? '#FBF2D2' :
                                                            '#FFFFFF',
                                    color: weekday.status == 0 ? {} : '#FFFFFF',
                                    padding: '0px',
                                    margin: '0px',
                                    border: 'none',
                                    verticalAlign: 'middle'
                                  }}
                                  key={d_key}>
                                  {weekday.day
                                    ? <Button
                                      block
                                      bsSize='xsmall'
                                      style={{
                                        border: 'none',
                                        backgroundColor: 'transparent'
                                      }}
                                      data-test-id='calendar-editor-day-button'
                                      disabled={weekday.status == -1 || weekday.status == -2}
                                      onClick={e => this._onDaySave(e, weekday.day.format("YYYYMMDD"))}>
                                      {weekday.day.format("D")}
                                    </Button>
                                    : <div>&nbsp;</div>}
                                </td>
                              )
                            })}
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div>
              )
            })}
          </div>
        </Body>
        <Footer>
          <Button onClick={this._close}>Close</Button>
        </Footer>
      </Modal>
    )
  }
}
