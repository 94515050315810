// @flow

import { connect } from 'react-redux'

import {
  receiveServiceCalendar,
  fetchServiceCalendar,
  saveServiceCalendarSelection
} from '../actions/calendar'
import CalendarEditorModal from '../components/CalendarEditorModal'
import {findProjectByFeedSource} from '../../manager/util'

import type {AppState, RouteParams} from '../../types/reducers'

export type Props = {
  routeParams: RouteParams,
  onClose: () => void,
  show: boolean
}

const mapStateToProps = (state: AppState, ownProps: Props) => {
  const {active, tables} = state.editor.data
  const {
    entityId: activeEntityId
  } = active
  const {calendarEditorState} = state.editor
  const {onClose, show} = ownProps
  const {feedSourceId} = ownProps.routeParams
  const project = findProjectByFeedSource(state.projects.all, feedSourceId)
  const feedSource = project && project.feedSources && project.feedSources.find(fs => fs.id === feedSourceId)
  const {entity: activeServiceCalendar} = active
  const {service_id: activeServiceId} = activeServiceCalendar

  console.log("Calendar Editor Status: ", state.editor)
  const calendarEditorStatus = state.editor.calendarEditorState.status
  return {
    activeEntityId,
    activeServiceId,
    activeServiceCalendar,
    feedSource,
    tableData: tables,
    calendarEditorState,
    calendarEditorStatus,
    onClose,
    show
  }
}

const mapDispatchToProps = {
  receiveServiceCalendar,
  fetchServiceCalendar,
  saveServiceCalendarSelection
}

const ActiveCalendarEditorModal = connect(mapStateToProps, mapDispatchToProps)(CalendarEditorModal)

export default ActiveCalendarEditorModal
