// @flow

import Icon from '@conveyal/woonerf/components/icon'
import React, {Component} from 'react'
import { Button, ButtonGroup, DropdownButton, Dropdown, MenuItem, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { browserHistory } from 'react-router'

import * as activeActions from '../actions/active'
import * as mapActions from '../actions/map'
import * as snapshotActions from '../actions/snapshots'

import {
  exportSchedules, 
  exportTicketingSystemIntegration, 
  exportTicketingSystemIntegration2,
  exportSchedulesAdvanced,
  exportBusStopSigns
} from '../../manager/actions/versions'
import * as commonActions from '../../common/actions'
import GenSchedulesModal from '../../common/components/GenSchedulesModal.js'

import SelectFileModal from '../../common/components/SelectFileModal.js'
import {getComponentMessages, getIntegrationConfig} from '../../common/util/config'
import {isValidZipFile} from '../../common/util/util'
import { GTFS_ICONS } from '../util/ui'
import {componentToText} from '../util/objects'
import {ENTITY} from '../constants'
import type {Props as ContainerProps} from '../containers/ActiveFeedInfoPanel'
import type {Feed, FeedInfo, Project} from '../../types'

import CreateSnapshotModal from './CreateSnapshotModal'

type Props = ContainerProps & {
  displayRoutesShapefile: typeof mapActions.displayRoutesShapefile,
  feedInfo: FeedInfo,
  feedSource: Feed,
  fetchSnapshots: typeof snapshotActions.fetchSnapshots,
  project: Project,
  restoreSnapshot: typeof snapshotActions.restoreSnapshot,
  setActiveEntity: typeof activeActions.setActiveEntity,
  exportSchedules: typeof activeActions.exportSchedules,
  exportSchedulesAdvanced: typeof activeActions.exportSchedulesAdvanced,
  exportBusStopSigns: typeof versionsActions.exportBusStopSigns,
  cgiJobProgress: typeof commonActions.cgiJobProgress,
  exportTicketingSystemIntegration: typeof activeActions.exportTicketingSystemIntegration,
  exportTicketingSystemIntegration2: typeof activeActions.exportTicketingSystemIntegration2,
}

type State = {
  right: number
}

const PANEL_WIDTH = 450

/**
 * This is a hideable menu of shortcut buttons that is displayed within the
 * Editor.
 */
export default class FeedInfoPanel extends Component<Props, State> {
  messages = getComponentMessages('FeedInfoPanel')
  integrationConfig=getIntegrationConfig()
  state = {
    right: 5
  }

  _onAddSelect = (key: string) => {
    this.props.setActiveEntity(this.props.feedSource.id, key, {id: ENTITY.NEW_ID})
  }

  _onDropdownArrowClick = () => this.props.fetchSnapshots(this.props.feedSource)

  _onNavigate = (key: string) => {
    switch (key) {
      case '1':
        return browserHistory.push(`/project/${this.props.project.id}`)
      case '2':
        return browserHistory.push(`/feed/${this.props.feedSource.id}`)
    }
  }

  _onSelectSnapshot = (key: string) => {
    const {editorSnapshots: snapshots} = this.props.feedSource
    const snapshot = snapshots && snapshots.find(s => s.id === key)
    snapshot && this.props.showConfirmModal({
      title: this.messages('restoreSnapshot.title').replace('%key%', key),
      body: this.messages('restoreSnapshot.body'),
      onConfirm: () => {
        this.props.restoreSnapshot(this.props.feedSource, snapshot)
      }
    })
  }

  _onToggleHide = () => {
    const toolbarVisible = this.state.right > 0
    if (toolbarVisible) {
      this.setState({right: 30 - PANEL_WIDTH})
    } else {
      this.setState({right: 5})
    }
  }

  _openSnapshotModal = () => {
    // Note: this will need to change if react-redux is upgraded to v6+
    // https://medium.com/octopus-labs-london/how-to-access-a-redux-components-methods-with-createref-ca28a96efd59
    this.refs.snapshotModal.getWrappedInstance().open()
  }

  _onDownloadSelect = (key: string) => {
    const {displayRoutesShapefile, feedSource, project} = this.props
    const tsiCodes=this.integrationConfig.TicketingSystemIntegration.CGI_Download
    const tsiCodes2=this.integrationConfig.TicketingSystemIntegration.CGI_Download2
    const tsiCode=feedSource.ticketing_system_integration_code
    var schedulesAdvanced_CGI=this.integrationConfig.SchedulesAdvancedCustomization._default;
    if (this.integrationConfig.SchedulesAdvancedCustomization[project.name]!==undefined) {
      if (this.integrationConfig.SchedulesAdvancedCustomization[project.name][feedSource.name]!==undefined) {
        schedulesAdvanced_CGI=this.integrationConfig.SchedulesAdvancedCustomization[project.name][feedSource.name];
      }
    }
    let title='???...'
    let notes='???...'
    switch (key) {
      case '1':
        title='Export Excel Schedules'
        notes='Excel file with one timetable worksheet per route (only timepoints)'
        break
      case '2':
        title='Export file (ticketing system integration)'
        notes=tsiCodes[tsiCode].description
        break
      case '3':
        title='Export file (ticketing system integration)'
        notes=tsiCodes2[tsiCode].description
        break
      case '4':
        title='Export Stop Schedule PDF\'s'
        notes='ZIP file containing one schedule PDF per stop and per page'
        break
              
    }
    this.refs.genSchedulesModal.open({
      editor: true,
      download_key: key,
      title: title,
      notes: notes,
      runJob: (job_id, key, compareVersionId) => {
        switch (key) {
          case '1':
            this.props.exportSchedules(
              this.props.feedSource.id, 
              'feed', 
              job_id,
              null
            )            
            break
          case '2':
            this.props.exportTicketingSystemIntegration(
              this.props.feedSource.id,
              'feed', 
              this.props.feedSource.ticketing_system_integration_code, 
              job_id
            )
            break
          case '3':
            this.props.exportTicketingSystemIntegration2(
              this.props.feedSource.id,
              'feed', 
              this.props.feedSource.ticketing_system_integration_code, 
              job_id
            )
            break
          case '4':
            this.props.exportSchedulesAdvanced(
              this.props.feedSource.id, 
              'feed', 
              schedulesAdvanced_CGI,
              job_id,
              null
            )            
            break
          }
        return true;
      }
    })
  }

  showUploadFileModal = () => {
    const {displayRoutesShapefile, feedSource} = this.props
    this.refs.selectFileModal.open({
      title: this.messages('uploadShapefile.title'),
      body: this.messages('uploadShapefile.body'),
      onConfirm: (files) => {
        const file = files[0]
        if (isValidZipFile(file)) {
          displayRoutesShapefile(feedSource, file)
          return true
        } else {
          return false
        }
      },
      errorMessage: this.messages('uploadShapefile.error')
    })
  }

  render () {
    const { feedSource, feedInfo, user } = this.props
    if (!feedInfo) return null
    const panelStyle = {
      position: 'absolute',
      right: this.state.right,
      bottom: 20,
      zIndex: 500,
      borderRadius: '5px',
      width: `${PANEL_WIDTH}px`
    }
    if (!feedInfo || !feedSource) {
      return null
    }
    const toolbarVisible = this.state.right > 0
    const feedName = feedSource && feedSource.name && feedSource.name.length > 10
      ? feedSource.name.substr(0, 10) + '...'
      : feedSource && feedSource.name
        ? feedSource.name
        : this.messages('unnamed')

    const tsiCodes=this.integrationConfig.TicketingSystemIntegration.CGI_Download
    const tsiCodes2=this.integrationConfig.TicketingSystemIntegration.CGI_Download2
    const tsiCode=feedSource.ticketing_system_integration_code
    const isAppAdmin=(user && user.permissions && user.permissions.isApplicationAdmin())

    return (
      <div>
        <SelectFileModal ref='selectFileModal' />
        <GenSchedulesModal ref='genSchedulesModal' {...this.props} />
        <div style={panelStyle}>
          <CreateSnapshotModal
            ref='snapshotModal'
            feedSource={feedSource}
          />
          <ButtonGroup className='button-group-editor'>
            {/* Hide toolbar toggle */}
            <OverlayTrigger placement='top' overlay={<Tooltip id='hide-tooltip'>{toolbarVisible ? this.messages('toolbar.hide') : this.messages('toolbar.show')}</Tooltip>}>
              <Button
                data-test-id='FeedInfoPanel-visibility-toggle'
                onClick={this._onToggleHide}
              >
                <Icon type={toolbarVisible ? 'caret-right' : 'caret-left'} />
              </Button>
            </OverlayTrigger>


            <DropdownButton
              dropup
              title={<span><Icon type='download' /></span>}
              id='download-dropdown'>
              {tsiCodes2[tsiCode]
                ?
                  <MenuItem 
                    eventKey='3'
                    onSelect={this._onDownloadSelect}
                  ><Icon type={tsiCodes2[tsiCode].icon} /> {tsiCodes2[tsiCode].description}</MenuItem>
                :null
              }
              {tsiCodes[tsiCode]
                ?
                  <MenuItem 
                    eventKey='2'
                    onSelect={this._onDownloadSelect}
                  ><Icon type={tsiCodes[tsiCode].icon} /> {tsiCodes[tsiCode].description}</MenuItem>
                :null
              }
              <MenuItem 
                eventKey='4'
                onSelect={this._onDownloadSelect}
                ><Icon type='file-zip-o' /> Stop Schedule PDF's (.zip)
              </MenuItem>
              <MenuItem 
                eventKey='1'
                onSelect={this._onDownloadSelect}
                ><Icon type='file-excel-o' /> Schedules (.xlsx)
              </MenuItem>
            </DropdownButton>


            {/* Navigation dropdown */}
            <DropdownButton
              dropup
              title={<span title={this.messages('editingFeed').replace('%feed%', feedSource && feedSource.name)}>{this.messages('editingFeed').replace('%feed%', feedName)}</span>}
              id='navigation-dropdown'
              onSelect={this._onNavigate}>
              <MenuItem eventKey='1'><Icon type='reply' /> {this.messages('backToProject')}</MenuItem>
              <MenuItem eventKey='2'><Icon type='reply' /> {this.messages('backToFeedSource')}</MenuItem>
            </DropdownButton>
            <Button
              onClick={this.showUploadFileModal}>
              <Icon type='upload' />
            </Button>
            {/* Add entity dropdown */}
            <DropdownButton
              pullRight dropup
              title={<span><Icon type='plus' /></span>}
              id='add-entity-dropdown'
              onSelect={this._onAddSelect}>
              {GTFS_ICONS.map(c => {
                if (!c.addable) return null
                return (
                  <MenuItem
                    key={c.id}
                    eventKey={c.id}>
                    <Icon type={c.icon} /> {this.messages('add').replace('%id%', componentToText(c.id))}
                  </MenuItem>
                )
              })}
            </DropdownButton>

            {/* Snapshot dropdown */}
            {
              <Dropdown
                dropup
                pullRight
                id='snapshot-dropdown'
                onSelect={this._onSelectSnapshot}>
                <OverlayTrigger
                  placement='top'
                  overlay={<Tooltip id='snapshot-tooltip'>{this.messages('takeSnapshot')}</Tooltip>}>
                  <Button
                    bsStyle='primary'
                    data-test-id='take-snapshot-button'
                    onClick={this._openSnapshotModal}>
                    <Icon type='camera' />
                  </Button>
                </OverlayTrigger>
                <Dropdown.Toggle
                  bsStyle='primary'
                  onClick={this._onDropdownArrowClick} />
                <Dropdown.Menu style={{maxHeight: '200px', overflowY: 'scroll'}}>
                  {feedSource &&
                    feedSource.editorSnapshots &&
                    feedSource.editorSnapshots.length > 0
                    ? feedSource.editorSnapshots.map(snapshot => {
                      return (
                        <MenuItem
                          key={snapshot.id}
                          eventKey={snapshot.id}>
                          <Icon type='reply'/> {this.messages('revert').replace('%snapshot%', snapshot.name)}
                        </MenuItem>
                      )
                    })
                    : <MenuItem
                      disabled
                      eventKey={null}>
                      <em>{this.messages('noSnapshot')}</em>
                    </MenuItem>
                  }
                </Dropdown.Menu>
              </Dropdown>
            }
          </ButtonGroup>
        </div>
      </div>
    )
  }
}
